import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { userLoginHook } from "../../hooks/userHooks";
import { AddEventCommissionersModal } from '../AddEventComissioner/AddEventCommissionersModal';
import { ABOClientApi } from "../../api/ABOApi";

interface CommissionerInfo {
  user_id: string;
  user_firstname: string;
  user_lastname: string;
  user_image: string;
  info: any;
  // Add other Commissioner properties as needed
}

interface ViewEventCommissionersProps {
  event_info: any;
}

export const ViewEventCommissioners: React.FC<ViewEventCommissionersProps> = ({ event_info }) => {
  const [ user, setUser ]: any = userLoginHook();
  const [ eventCommisioners , setEventCommissioners] = React.useState<CommissionerInfo[]>([]);

  const getEventCommissioners = async() =>{
    try{
      const response: any = await ABOClientApi.get_event_commissioners("", event_info.event_id.toString());
      console.log("getEventCommissioners", response);
      setEventCommissioners(response);
    }catch(error){
      console.log("getEventCommissioners Error", error);
    };
  };

  React.useEffect(() => {
    getEventCommissioners();
  }, []);

  return (
    <>
      <Box>
        <div style={{
          // display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
          width: "100%"
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Event Commissioners
            {
              user && JSON.parse(user).user_role === "abo_admin" && <AddEventCommissionersModal event_info={event_info} getEventCommissioners={getEventCommissioners}/>
            }
          </Typography>
        </div>
        {
          eventCommisioners.map(( commissioner: CommissionerInfo ) => (
            <Box key={commissioner?.info?.user_id} mb={3} style={{ padding: "5px", border: "1px white solid"}}>
              <Card sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography id="modal-title" variant="h6" component="h2" gutterBottom> 
                    { commissioner?.info?.user_firstname}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{ width: 150, height: 150, objectFit: 'cover' }}
                    image={commissioner.info?.user_image}
                    alt={commissioner.info?.user_firstname}
                  />
                  <Typography variant="body2" mt={1}>
                    {/* Add extra boxer information here */}
                    {commissioner.info?.user_lastname}
                  </Typography>
                </Box>
              </Card>
            </Box>
          ))
        }
      </Box>
    </>
  );
};