import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Typography, Tabs, Tab } from '@mui/material';
import { ViewEventBouts } from './ViewEventBouts';
import { ViewEventCommissioners } from './ViewEventCommissioners';
import { ViewEventJudges } from './ViewEventJudges';

interface EventsMoreInfoModalProps {
  event_info: any[]
}

export const EventsMoreInfoModal: React.FC<EventsMoreInfoModalProps> = ({event_info}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => { 
    console.log("event_info", event_info);
    setOpen(true)
  };
  const handleClose = () => setOpen(false);


  return (
    <>
      <Button onClick={handleOpen}>More Info</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '800px',
            borderRadius: '16px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
          }
        }}
      >        
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            The Title
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <EventInfoTabs event_info={event_info}/>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button color="secondary" variant="contained" onClick={ ()=>handleClose() }>
              Close
            </Button> 
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface EventInfoTabsProps {
  event_info: any
}

const EventInfoTabs: React.FC<EventInfoTabsProps> = ( {event_info} ) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Bouts" {...a11yProps(0)} />
          <Tab label="Commisioners" {...a11yProps(1)} />
          <Tab label="Judges" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <ViewEventBouts event_info={event_info} />   
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ViewEventCommissioners event_info={event_info} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ViewEventJudges event_info={event_info} />
      </CustomTabPanel>
    </Box>
  );
}