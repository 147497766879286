interface ImageSelectorProps {
    selectedImage: File | null;
    setSelectedImage: React.Dispatch<React.SetStateAction<File | null>>;
    base64Image: string;
    setBase64Image: React.Dispatch<React.SetStateAction<string>>;
    boxerInfo: any;
    setBoxerInfo: React.Dispatch<React.SetStateAction<any>>;
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({ selectedImage, setSelectedImage, base64Image, setBase64Image, boxerInfo, setBoxerInfo }) => {
    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0] || null;
        console.log("file: ", file);
        if (file) {
            setSelectedImage(file);
            convertToBase64(file);
        }
    };

    const convertToBase64 = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setBase64Image(reader.result as string);
            setBoxerInfo({ ...boxerInfo, boxerImage: reader.result as string });
        };
    };

    return (
        <div>
            <input type="file" accept="image/*" onChange={handleImageChange} />
            {
              base64Image && (
                <div>
                  <img src={base64Image} alt="Selected" style={{ width: '200px' }} />
                </div>
            )}
        </div>
    );
};

