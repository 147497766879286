import * as React from "react";
import { Typography, TextField, Button } from "@mui/material/";
import { ABOClientApi } from "../../api/ABOApi";
import { useNavigate } from "react-router-dom";
import { menuHook } from "../../hooks/menuHook";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import "./LoginCard.css";

export const LoginCard = () => {

  const [ loginCreds, setLoginCreds] = React.useState({username: "", password: ""});
  const [ menuItem, setMenuItem ]: any = menuHook();
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ status, setStatus ] = React.useState("");
  const navigate = useNavigate();

  const login = async () => {
    // console.log("login creds", loginCreds)
    const validate: any = await validateBoxerInfo();
    console.log("validate", Object.keys(validate).length);
    Object.keys(validate).length > 0 && setStatus(Object.values(validate).join(', '));
    if(Object.keys(validate).length > 0)return;
    try {
      setStatus("Logging in...");
      const response = await ABOClientApi.login_user(loginCreds.username, loginCreds.password);
      console.log("login response", response);
      setStatus(response);
      const user = JSON.parse(response);
      setUser(JSON.stringify(user[0]));
      setJWT(user[1]);
      user[0].user_role === "abo_admin" && setMenuItem("WelcomeAdmin");
      user[0].user_role === "abo_admin" && navigate("/loginDashboardAdmin");
      console.log("login response", user);
    } catch (error) {
      console.log("login Error", error);
      // setStatus(`Login Error: ${error}`);
    }
  }

  const handleInputChange = (field: string, event: any) => {
    // console.log("input change ", event.target.value)
    setLoginCreds({ ...loginCreds, [field]: event.target.value });
  };

  const validateBoxerInfo: any = async () => {
    const errors: any = {};
    !loginCreds.username.trim() && (errors.username = 'Username is reuiqred');
    !loginCreds.password.trim() && (errors.password = 'Password is required');

    return errors;
  };

  return (
    <div className="LoginCard">
      <Typography variant="h5">Welcome please login</Typography>
      <form>
        <TextField
          style={{ width: "200px", margin: "5px" }}
          type="text"
          label="Username"
          variant="outlined"
          value={loginCreds.username}
          onChange={(event: any) => handleInputChange("username", event)}
        />
        <br />
        <TextField
          style={{ width: "200px", margin: "5px" }}
          type="password"
          label="password"
          variant="outlined"
          value={loginCreds.password}
          onChange={(event: any) => handleInputChange("password", event)}
        />
        <br />
        <Button variant="contained" color="primary" onClick={()=>login()}>
          Login
        </Button>
        <br />
        {status && <div>{status}</div>}
      </form>
    </div>
  );
}