// EditBoxerModal.tsx

import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { ImageSelector } from './imageTools';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment'; // Ensure moment is installed
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import { ABOClientApi } from "../../api/ABOApi";
import CountrySelect from "./CountrySelect";

interface BoxerInfo {
  boxer_id: string;
  boxer_firstname: string;
  boxer_lastname: string;
  boxer_gender: string;
  boxer_dob: string;
  boxer_weight: string;
  boxer_height: string;
  boxer_reach: string;
  boxer_stance: string;
  boxer_division: string;
  boxer_email: string;
  boxer_phone: string;
  organization_id: string;
  boxer_country_origin:string;
  boxer_country_residence: string;
  boxer_city_origin: string;
  boxer_city_residence: string;
  boxer_image: string;
}

interface EditBoxerModalProps {
  boxer: BoxerInfo;
  getBoxers: any;
}

export const EditBoxerModal: React.FC<EditBoxerModalProps> = ({ boxer, getBoxers }) => {
  const [ open, setOpen ] = useState(false);
  const [ dobValue, setDobValue ] = useState<moment.Moment | null>(null);
  const [ orgs, setOrgs ] = React.useState<any>([]);
  const [ base64Image, setBase64Image ] = React.useState<string>('');
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ checked, setChecked ] = React.useState(false);
  const [ status, setStatus ] = React.useState<string>("");
  
  const [ editedBoxer, setEditedBoxer ] = useState<BoxerInfo>({
    boxer_id: boxer.boxer_id,
    boxer_firstname: boxer.boxer_firstname, 
    boxer_lastname: boxer.boxer_lastname, 
    boxer_gender: boxer.boxer_gender, 
    boxer_dob: boxer.boxer_dob, 
    boxer_weight: boxer.boxer_weight, 
    boxer_height: boxer.boxer_height,  
    boxer_reach: boxer.boxer_reach, 
    boxer_stance: boxer.boxer_stance, 
    boxer_division: boxer.boxer_division, 
    boxer_city_origin: boxer.boxer_city_origin, 
    boxer_country_origin:  boxer.boxer_country_origin, 
    boxer_city_residence:  boxer.boxer_city_residence, 
    boxer_country_residence:  boxer.boxer_country_residence, 
    organization_id:  boxer.organization_id, 
    boxer_email:  boxer.boxer_email, 
    boxer_phone:  boxer.boxer_phone, 
    boxer_image: base64Image 
  });

  const handleOpen = async () => {
    setOpen(true);
    setBase64Image(boxer.boxer_image);
    getOrgs();
  };
  const handleClose = () => setOpen(false);

  const saveChanges = async () => {
    console.log('Changes to be saved:', editedBoxer);
    try{
      const editBoxerRes = await ABOClientApi.edit_boxer(
        jwt, 
        editedBoxer.boxer_id.toString(),
        editedBoxer.boxer_firstname,
        editedBoxer.boxer_lastname,
        editedBoxer.boxer_gender,
        editedBoxer.boxer_dob.toString(),
        editedBoxer.boxer_weight,
        editedBoxer.boxer_height,
        editedBoxer.boxer_reach,
        editedBoxer.boxer_stance,
        editedBoxer.boxer_division,
        editedBoxer.boxer_city_origin,
        editedBoxer.boxer_country_origin,
        editedBoxer.boxer_city_residence,
        editedBoxer.boxer_country_residence,
        editedBoxer.organization_id.toString(),
        editedBoxer.boxer_email,
        editedBoxer.boxer_phone,
        editedBoxer.boxer_image
      );
      editBoxerRes === "ok" && alert("Boxer Updated");
    }catch(error){
      console.log("saveChanges Error", error);
      alert("Error Updating Boxer");
    };
    // Here you would implement your API call to save the changes
    // handleClose();
  };

  const getOrgs = async () => {
    try {
      const response = await ABOClientApi.get_all_organizations();
      console.log(response);
      setOrgs(response);
    } catch (error) {
      console.log("getOrgs Error", error);
    }
  };

  const handleInputChange = (field: string, event: any) => {
    // console.log("input change ", field, event )
    // console.log("input change ", event.target.value)
    // setBoxerInfo({ ...boxerInfo, [field]: event.target.value });
    setEditedBoxer({ ...editedBoxer, [field]: event.target.value })
  };

  const handleCountryOriginChange = (event: any) => {
    console.log("country change", event)
    setEditedBoxer({ ...editedBoxer, ["boxer_country_origin"]: JSON.stringify(event) });
  };

  const handleCountryResidenceChange = (event: any) => {
    setEditedBoxer({ ...editedBoxer, ["boxer_country_residence"]: JSON.stringify(event) });
  }; 

  const handleDateChange = (newValue: any) => {
    setDobValue(newValue);
    setEditedBoxer({ ...editedBoxer, boxer_dob: newValue.format("X") });
  };

  const SelectDivision = () => {
    return (
        <FormControl sx={{ width: 200, margin: "5px", float: "left", textAlign: "left" }}>
          <InputLabel id="select_division_label">Divison</InputLabel>
          <Select
            labelId="select_division_label"
            id="select_division"
            value={editedBoxer.boxer_division}
            label="Select Division"
            onChange={(event: any) => handleInputChange("boxer_division", event)}
          >
            <MenuItem value={"Light Flyweight"}>Light Flyweight</MenuItem>
            <MenuItem value={"Flyweight"}>Flyweight</MenuItem>
            <MenuItem value={"Super Flyweight"}>Super Flyweight</MenuItem>
            <MenuItem value={"Bantamweigh"}>Bantamweight</MenuItem>
            <MenuItem value={"Super Bantamweight"}>Super Bantamweight</MenuItem>
            <MenuItem value={"Featherweight"}>Featherweight</MenuItem>
            <MenuItem value={"Super featherweight"}>Super featherweight</MenuItem>
            <MenuItem value={"Lightweight"}>Lightweight</MenuItem>
            <MenuItem value={"Super Lightweight"}>Super Lightweight</MenuItem>
            <MenuItem value={"Welterweight"}>Welterweight</MenuItem>
            <MenuItem value={"Super Welterweight"}>Super Welterweight</MenuItem>
            <MenuItem value={"Middleweight"}>Middleweight</MenuItem>
            <MenuItem value={"Super Middleweight"}>Super Middleweight</MenuItem>
            <MenuItem value={"Light Heavyweight"}>Light Heavyweight</MenuItem>
            <MenuItem value={"Cruiserweight"}>Cruiserweight</MenuItem>
            <MenuItem value={"Heavyweight"}>Heavyweight</MenuItem>
          </Select>
        </FormControl>
    );
  };

  const SelectGender = () => {
    return(
      <FormControl sx={{ width: 200, float: "left", margin: "5px", textAlign: "left" }}>
        <InputLabel id="select_gender_label">Gender</InputLabel>
        <Select
          labelId="select_gender_label"
          id="select_gender"
          value={editedBoxer.boxer_gender}
          label="Select Gender"
          onChange={(event: any) => handleInputChange("boxer_gender", event)}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const SelectStance = () => {
    return(
      <>
      <FormControl sx={{ width: 200, float: "left", margin: "5px", textAlign: "left" }}>
        <InputLabel id="select_stance_label">Stance</InputLabel>
        <Select
          labelId="select_stance_label"
          id="select_stance"
          value={editedBoxer.boxer_stance}
          label="Select Stance"
          onChange={(event: any) => handleInputChange("boxer_stance", event)}
        >
          <MenuItem value="orthodox">Orthodox</MenuItem>
          <MenuItem value="southpaw">Southpaw</MenuItem>
        </Select>
      </FormControl>
      </>
    );
  };
  
  const SelectOrg = () => {
    return(
      <FormControl sx={{ width: 200, float: "left", margin: "5px", textAlign: "left" }}>
        <InputLabel id="select_org_label">Organization</InputLabel>
        <Select
          labelId="select_org_label"
          id="select_org"
          value={editedBoxer.organization_id}
          label="Select Organization"
          onChange={(event: any) => handleInputChange("organization_id", event)}
        >
          {
            orgs && orgs.map((org: any) => (
              <MenuItem value={org.organization_id.toString()}>{org.organization_name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };

  const HandleDeleteBoxerCheck = ({}) => {
    return (
      <div onClick={() => setChecked(!checked)}>
        <Typography style={{float: "left"}}>
          Enable Delete only last result.      
        </Typography>        
        {checked ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : (
          <CheckCircleOutlineIcon style={{ color: 'grey' }} />
        )}
      </div>
    );
  };

  const handleDeleteBoxer = async() => {
    try{
      const response = await ABOClientApi.delete_boxer(
        jwt,
        editedBoxer.boxer_id.toString()
      );
      // console.log("deleteBoxer", response);
      response === "ok" && alert("Boxer Deleted");
      response === "ok" && getBoxers();
      response === "ok" &&  handleClose();
    }catch(error){
      console.log("deleteBoxer Error", error);
      alert("Error Deleting Boxer");
    };
  };

  return (
    <>
      <Button variant='contained' onClick={handleOpen}>Edit</Button>
      <Dialog 
        open={open} 
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '500px',
            borderRadius: '16px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
          }
        }}
      >
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}> 
            Edit Boxer Information 
            {checked && <Button variant='contained' onClick={()=>handleDeleteBoxer()} >Delete</Button>}
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <form style={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
              <ImageSelector
                base64Image={base64Image}
                setBase64Image={setBase64Image}
                boxerInfo={editedBoxer}
                setBoxerInfo={setEditedBoxer}
              />
            
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Boxer ID"
                variant="outlined"
                value={editedBoxer.boxer_id}
                onChange={(event: any) => handleInputChange("boxer_id", event)}
              />
            
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="First Name"
                variant="outlined"
                value={editedBoxer.boxer_firstname}
                onChange={(event: any) => handleInputChange("boxer_firstname", event)}
              />
              
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Last Name"
                variant="outlined"
                value={editedBoxer.boxer_lastname}
                onChange={(event: any) => handleInputChange("boxer_lastname", event)}
              />
              
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Email Address"
                variant="outlined"
                value={editedBoxer.boxer_email}
                onChange={(event: any) => handleInputChange("boxer_email", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Phone Number(123-456-7890)"
                variant="outlined"
                value={editedBoxer.boxer_phone}
                onChange={(event: any) => handleInputChange("boxer_phone", event)}
              />

              <SelectGender />

              <div style={{ width: "200px", margin: "5px"}}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="Boxer DOB"
                    value={dobValue}
                    onChange={(newValue: any) => {
                      handleDateChange(newValue)
                    }}
                  />
                </LocalizationProvider>
              </div>

              <TextField
                style={{ width: "200px", margin: "5px"}}
                type="number"
                label="Boxer Weight(lbs)"
                variant="outlined"
                value={editedBoxer.boxer_weight}
                onChange={(event: any) => handleInputChange("boxer_weight", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px"}}
                type="number"
                label="Boxer Height(inches)"
                variant="outlined"
                value={editedBoxer.boxer_height}
                onChange={(event: any) => handleInputChange("boxer_height", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="number"
                label="Boxer Reach(inches)"
                variant="outlined"
                value={editedBoxer.boxer_reach}
                onChange={(event: any) => handleInputChange("boxer_reach", event)}
              />
              <SelectStance />

              <SelectDivision />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Boxer City Origin"
                variant="outlined"
                value={editedBoxer.boxer_city_origin}
                onChange={(event: any) => handleInputChange("boxer_city_origin", event)}
              />       
              
              <CountrySelect handleInputChange={handleCountryOriginChange} label="Country Of Origin" />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Boxer City of Residence"
                variant="outlined"
                value={editedBoxer.boxer_city_residence}
                onChange={(event: any) => handleInputChange("boxer_city_residence", event)}
              />

              <CountrySelect handleInputChange={handleCountryResidenceChange} label="Country Of Residence" />

              <SelectOrg />
            </form>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <HandleDeleteBoxerCheck />
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={saveChanges} color="primary">
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
