import { createTheme } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";

export const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#3187A2", // This will be your navbar color
      
    },
    // You can define other colors here if needed
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF", // Alternatively, use this for direct override
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  
});

export default {
  darkTheme,
  lightTheme,
};
