import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Box, Button, Typography, FormControl, InputLabel, Select, MenuItem,  } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import { jwtHook } from "../../hooks/userHooks";

interface AddBoutJudgeProps {
  bout_info: any;
}

export const AddBoutJudgesModal: React.FC<AddBoutJudgeProps> = ({ bout_info }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [ judges, setJudges ] = useState< string | any[]>([]); // [ { id: 1, name: 'John Doe' }, { id: 2, name: 'Jane Doe' }
  const [ jwt, setJWT ] = jwtHook<any>();
  const [ status, setStatus ] = useState("");
  const [ input, setInput ] = useState<any>({
    bout_id: bout_info.bout_id,
    user_id: ""
  }); // { event_id: 1, user_id: 1 }


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: "center"
  };

  const openModal = () => {
    setOpen(true);
    console.log(bout_info);
    getJudges();
  };

  const addJudge = async () => {
    console.log(input);
    console.log("jwt", jwt);
    if(input.user_id === ""){{setStatus("Select a Judge"); return}}
    try{
      const response = await ABOClientApi.add_bout_judge( 
        jwt, 
        input.bout_id.toString(), 
        input.user_id.toString()
      );
      console.log(response);
      response === "ok" && setStatus(`judge added to bout`);
    }catch(error){
      console.log("addJudge Error", error);
    };
  };

  const getJudges = async() =>{
    try{
      const judgesRes: string | any[] = await ABOClientApi.get_event_judges( jwt, bout_info.event_id.toString() );
      console.log("judgesRes", judgesRes);
      setJudges(judgesRes);
    }catch(error){
      console.log("getJudges Error", error);
    };
  };

  const SelectJudge: React.FC = ( ) => {
    return (
      <FormControl sx={{ width: 200, margin: "5px"}}>
        <InputLabel id="SelectJudge_label">Select Judge</InputLabel>
        <Select
          labelId="SelectJudge_label"
          id="SelectJudge"
          value={input.user_id}
          label="Judges"
          onChange={(event: any) => setInput({ ...input, user_id: event.target.value })}
        >
          {
            judges && Array.isArray(judges) && judges.map(( judge: any ) => (
              <MenuItem value={judge.info.user_id}>{judge.info.user_firstname} {judge.info.user_lastname}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <Button onClick={openModal} variant="contained">Add Judge</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        PaperProps={{
          style: {
            minWidth: '500px',
            borderRadius: '16px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
          }
        }}
      >
        
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            Add Judge to Bout
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <SelectJudge />
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button variant="contained" color="primary" onClick={ ()=>addJudge() }>
              Save
            </Button>
            <Button color="secondary" variant="contained" onClick={ ()=>handleClose() }>
              Close
            </Button> 
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddEventCommissionersForm = () => {};