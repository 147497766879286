import { useState } from "react";
import { createStore } from "reusable";

// export const userLoginHook = createStore( () => useState([]));

export const userLoginHook = createStore( () => {
    // tslint:disable-next-line: react-hooks-nesting
    const [ user, setUser ] = useState(sessionStorage.getItem("user"));
    // tslint:disable-next-line: no-shadowed-variable
    const handleSetUser = async (user: any) => {
      sessionStorage.setItem("user", user);
      return setUser(user);
    };
    return [user, handleSetUser];
});

export const jwtHook = createStore( () => {
    // tslint:disable-next-line: react-hooks-nesting
    const [ jwt, setJWT ] = useState<string | null>(sessionStorage.getItem("jwt"));
    // tslint:disable-next-line: no-shadowed-variable
    const handelSetJWT = async (jwt: any) => {
      sessionStorage.setItem("jwt", jwt);
      return setJWT(jwt);
    };
    return [jwt, handelSetJWT];
  });
  