import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, FormControl, InputLabel, Select, MenuItem  } from '@mui/material';

import { ABOClientApi } from "../../api/ABOApi";
import { jwtHook } from "../../hooks/userHooks";

interface AddBoutCommissionerProps {
  bout_info: any;
}

export const AddBoutCommissionerModal: React.FC<AddBoutCommissionerProps> = ({ bout_info }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [ commissioners, setCommissioners ] = useState< string | any[]>([]); // [ { id: 1, name: 'John Doe' }, { id: 2, name: 'Jane Doe' }
  const [ jwt, setJWT ] = jwtHook<any>();
  const [ status, setStatus ] = useState("");
  const [ input, setInput ] = useState<any>({
    bout_id: bout_info.bout_id,
    user_id: ""
  }); // { event_id: 1, user_id: 1 }


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: "center"
  };

  const openModal = () => {
    setOpen(true);
    console.log(bout_info);
    getCommissioners();
  };

  const addCommissioner= async () => {
    console.log(input);
    console.log("jwt", jwt);
    if(input.user_id === ""){{setStatus("Select a Commissioner"); return}}
    try{
      const response = await ABOClientApi.add_bout_commissioner( 
        jwt, 
        input.bout_id.toString(), 
        input.user_id.toString()
      );
      console.log(response);
      response === "ok" && setStatus(`Commissioner added to bout`);
    }catch(error){
      console.log("addCommissioner Error", error);
    };
  };

  const getCommissioners = async() => {
    const commissionersRes: string | any[] = await ABOClientApi.get_event_commissioners( 
      jwt, 
      bout_info.event_id.toString()
    );
    console.log("commissionersRes", commissionersRes);
    setCommissioners(commissionersRes);
  };

  const Selectcommissioner: React.FC = ( ) => {
    return (
      <FormControl sx={{ width: 200, margin: "5px"}}>
        <InputLabel id="SelectJcommissioner_label">Select Commissioner</InputLabel>
        <Select
          labelId="Selectcommissioner_label"
          id="SelectCommissioner"
          value={input.user_id}
          label="Commissioners"
          onChange={(event: any) => setInput({ ...input, user_id: event.target.value })}
        >
          {
            commissioners && Array.isArray(commissioners) && commissioners.map(( commissioner: any ) => (
              <MenuItem value={commissioner.info.user_id}>{commissioner.info.user_firstname} {commissioner.info.user_lastname}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <Button onClick={openModal} variant="contained">Add Commisioner</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{textAlign: "center"}}
      >
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            Add Commissioner to Bout
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <Selectcommissioner />
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button variant="contained" color="primary" onClick={ ()=>addCommissioner() }>
              Save
            </Button>
            <Typography variant="body2" color="text.secondary" style={{textAlign: "center"}}>
                {status}
            </Typography>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddEventCommissionersForm = () => {};