import * as React from "react";
import { Typography, TextField, Button, FormControl, MenuItem, InputLabel, Select } from "@mui/material/";
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import CountrySelect from "./CountrySelect";
import { ImageSelector } from "./imageTools"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import "./AddEventCard.css";

export const AddEventCard = () => {
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ selectedImage, setSelectedImage ] = React.useState<any>("");
  const [ base64Image, setBase64Image ] = React.useState<string>('');
  const [ eventDate, setEventDate ] = React.useState<any | null>(null);
  const [ status, setStatus ] = React.useState("");
  const [ promoters, setPromoters ] = React.useState<any>([]);
  const [ comissioners, setComissioners ] = React.useState<any>([]);
  const [ eventInfo, setEventInfo] = React.useState({ 
    event_name: "",
    event_image: base64Image,
    event_date: "",
    event_venue: "",
    event_address: "",
    event_city: "",
    event_state: "",
    event_postalcode: "", 
    event_country: "", 
    event_type: "",
    event_promoter: "",
    event_text: "",
    event_display_image: "1",
    event_enabled: "1"
  });

  const addEvent = async () => {
    //console.log("user", user)
    console.log("event info", eventInfo);
    console.log("jwt", jwt);
    const validate: any = await validateEventForm();
    console.log("validate", Object.keys(validate).length);
    Object.keys(validate).length > 0 && setStatus(Object.values(validate).join(', '));
    if(Object.keys(validate).length > 0)return;
    try {
      setStatus("Adding Event...");
      const response = await ABOClientApi.add_event(
        jwt, 
        eventInfo.event_name, 
        eventInfo.event_image, 
        eventInfo.event_date, 
        eventInfo.event_venue, 
        eventInfo.event_address, 
        eventInfo.event_city, 
        eventInfo.event_state, 
        eventInfo.event_postalcode, 
        eventInfo.event_country, 
        eventInfo.event_type, 
        eventInfo.event_promoter, 
        eventInfo.event_text, 
        eventInfo.event_display_image.toString(), 
        eventInfo.event_enabled
      );
      setStatus(response);
      console.log(response);
      response === "ok" && setStatus(`Event ${eventInfo.event_name} Added.`)
    } catch (error) {
      console.log("registerOrg Error", error);
    }
  };  

  const handleInputChange = (field: string, event: any) => {
    //console.log("input change ", field, event )
    setEventInfo({ ...eventInfo, [field]: event.target.value });
  };
  const handleCountryOriginChange = (event: any) => {
    console.log("country change", event)
    setEventInfo({ ...eventInfo, ["event_country"]: JSON.stringify(event) })
  };
  const handleDateChange = (newValue: any) => {
    const dateLinux = newValue.format("X");
    // console.log("date change", dateLinux)
    // console.log("date change", new Date(dateLinux*1000).toLocaleString());

    setEventDate(newValue);
    setEventInfo({ ...eventInfo, event_date: dateLinux });
  };
  const EventEnabled = () => {
    return (
      <FormControl sx={{ width: 200, margin: "5px", float: "left" }}>
        <InputLabel id="selectEventEnabled_label">Event Enabled</InputLabel>
        <Select
          labelId="selectEventEnabled_label"
          id="selectEventEnabled"
          value={eventInfo.event_enabled}
          label="Enabled"
          onChange={(event: any) => handleInputChange("enabled", event)}
        >
          <MenuItem value={1}>true</MenuItem>
          <MenuItem value={0}>false</MenuItem>
        </Select>
      </FormControl>
    );
  };
  const ImageEnabled = () => {
    return (
      <FormControl sx={{ width: 200, margin: "5px",  float: "left" }}>
        <InputLabel id="selectUserEnabled_label">Show Only Image</InputLabel>
        <Select
          labelId="selectUserEnabled_label"
          id="selectUserEnabled"
          value={eventInfo.event_display_image}
          label="Enabled"
          onChange={(event: any) => handleInputChange("event_display_image", event)}
        >
          <MenuItem value={1}>true</MenuItem>
          <MenuItem value={0}>false</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const validateEventForm: any = async () => {
    const errors: any = {};
    // !eventInfo.event_name.trim() && (errors.event_name = 'Event Name is required');
    // !eventInfo.event_date.trim() && (errors.event_date = 'Event date and time is required');
    // !eventInfo.organization_email.trim() && (errors.organization_email = 'Email is required') || !/\S+@\S+\.\S+/.test(orgInfo.organization_email) && (errors.organization_email = 'Email address is invalid');
    // !eventInfo.organization_phone.trim() && (errors.organization_phone = 'Phone number is required') || !/^\d{10}$\S+\-/.test(orgInfo.organization_phone) && (errors.organization_phone = 'Phone number is invalid');
    // !eventInfo.event_address.trim() && (errors.event_address = 'Address is required');
    // !eventInfo.event_city.trim() && (errors.event_city = 'City is required');
    // !eventInfo.event_state.trim() && (errors.event_state = 'State is required');
    // !eventInfo.event_postalcode.trim() && (errors.event_postalcode = 'Postal Code is required');
    // !eventInfo.event_country.trim() && (errors.event_country = 'Country is required');
    //!eventInfo.event_text.trim() && (errors.event_text = 'Event Text is required');
    // !eventInfo.event_promoter.trim() && (errors.event_promoter = 'Promoter is required');
    return errors;
  };


  return (
    <>
    <div style={{maxWidth: 800, textAlign: "left"}}>
      <Typography variant="h5">Add New Event</Typography>
      <hr />
      <form>
        <div style={{textAlign: "center"}}>
        <Typography>Event Image</Typography>        
        <ImageSelector
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
          base64Image={base64Image}
          setBase64Image={setBase64Image}
          eventInfo={eventInfo}
          setEventInfo={setEventInfo}
        />
        </div>
        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Event Name"
          variant="outlined"
          value={ eventInfo.event_name }
          onChange={(event: any) => handleInputChange("event_name", event)}
        />

        <div style={{ width: "200px", margin: "5px", float: "left" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DateTimePicker
              format="DD/MM/YYYY hh:mm a"
              label="Event Date/Time"
              value={eventDate}
              onChange={(newValue: any) => {
                handleDateChange(newValue)
              }}
            />
          </LocalizationProvider>
        </div>

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Event Venue"
          variant="outlined"
          value={eventInfo.event_venue}
          onChange={(event: any) => handleInputChange("event_venue", event)}
        />
        
        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Event Address"
          variant="outlined"
          value={eventInfo.event_address}
          onChange={(event: any) => handleInputChange("event_address", event)}
        />
        
        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Event City"
          variant="outlined"
          value={eventInfo.event_city}
          onChange={(event: any) => handleInputChange("event_city", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Event State"
          variant="outlined"
          value={eventInfo.event_state}
          onChange={(event: any) => handleInputChange("event_state", event)}
        />
        
        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Event Postalcode"
          variant="outlined"
          value={eventInfo.event_postalcode}
          onChange={(event: any) => handleInputChange("event_postalcode", event)}
        />

        <CountrySelect handleInputChange={handleCountryOriginChange} label="Event Country" />

        <TextField
          style={{ width: "700px", margin: "5px", float: "left" }}
          type="text"
          fullWidth
          label="Event Text/Description"
          variant="outlined"
          value={eventInfo.event_text}
          onChange={(event: any) => handleInputChange("event_text", event)}
        />

        <ImageEnabled />
        <EventEnabled />
      </form>
    </div>
    <div>
      <Button variant="contained" color="primary" onClick={ ()=>addEvent() }>
        Add Event
      </Button>
    </div>
    { status && <div><Typography variant="h5">{status}</Typography></div> }
    </>
  );
};