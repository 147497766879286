import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent, Button } from '@mui/material';
import { AddBoutRecordModal } from '../AddBoutRecordCard/AddBoutRecordModal';
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import { ABOClientApi } from "../../api/ABOApi";
import { AddBoutJudgesModal } from '../AddBoutJudges/AddBoutJudgeModal';
import { AddBoutCommissionerModal } from '../AddBoutCommissioner/AddBoutCommissioner';
import { useDarkMode } from '../../hooks/useDarkMode';
import { AddBoutScoresModal } from '../AddBoutScores/AddBoutScoresModal';
import "./ViewEventsTable.css";

interface BoxerInfo {
  boxer_firstname: string;
  boxer_image: string;
  boxer_weight: string;
  // Add other boxer properties as needed
}

interface Bout {
  id: number;
  boxer_id_1_info: BoxerInfo;
  boxer_id_2_info: BoxerInfo;
  judges?: string[];
  commissioner?: string;
  referee?: string;
  boutOfficials?: any;
}

interface ViewEventBoutsProps {
  event_info: any;
}

export const ViewEventBouts: React.FC<ViewEventBoutsProps> = ({ event_info }) => {
  const [ user, setUser ]: any = userLoginHook();
  const [ eventBouts , setEventBouts] = React.useState<Bout[]>([]);
  const [ jwt, setJWT ] = jwtHook<any>();
  const [ loading, setLoading ] = React.useState<boolean>(false);
  const [ status, setStatus ] = React.useState<string>("");
  const [ darkMode, setDarkMode ]: any = useDarkMode();

  const getEventBouts = async() =>{
    console.log("jwt", jwt);
    setLoading(true);
    try{
      const response: any = await ABOClientApi.get_bouts_by_event_id("", event_info.event_id.toString());
      console.log("getEventBouts", response);
      setEventBouts(response);
      setLoading(false);
    }catch(error){
      console.log("getEventBouts Error", error);
      setStatus("error");
      setLoading(false);
    };
  };

  const getBoutOfficials = async( bout_id: string ) =>{
    try{
      const response: any = await ABOClientApi.get_bout_officials(jwt, bout_id);
      console.log("getBoutOfficials", response);
      
    }catch(error){
      console.log("getBoutOfficials Error", error);
    };
  };

  React.useEffect(() => {
    getEventBouts();
  }, []);

  return (
    <>
      <Box>
        <div style={{
          // display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
          width: "100%"
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Event Bouts
            {
              user && JSON.parse(user).user_role === "abo_admin" && <AddBoutRecordModal />
            }
          </Typography>
        </div>
        {
          loading && <Typography variant="body2">Loading...</Typography>
        }
        {
          status === "error" && <Typography variant="body2">Error fetching event bouts</Typography>
        }
        {
          eventBouts.map(( bout:Bout ) => (
            <Box key={bout.id} mb={3} className={darkMode ? "dark-theme-box" : "light-theme-box"} style={{ padding: "5px", border: "1px white solid"}}>
              <Card sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography id="modal-title" variant="h6" component="h2" gutterBottom> 
                    {bout.boxer_id_1_info.boxer_firstname}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{ width: 150, height: 150, objectFit: 'cover' }}
                    image={bout.boxer_id_1_info.boxer_image}
                    alt={bout.boxer_id_1_info.boxer_firstname}
                  />
                  <Typography variant="body2" mt={1}>
                    {/* Add extra boxer information here */}
                    Weight: {bout.boxer_id_1_info.boxer_weight} lbs
                  </Typography>
                </Box>
                <CardContent sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="h4" component="div">
                    VS
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography id="modal-title" variant="h6" component="h2" gutterBottom> 
                    {bout.boxer_id_2_info.boxer_firstname}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{ width: 150, height: 150, objectFit: 'cover' }}
                    image={bout.boxer_id_2_info.boxer_image}
                    alt={bout.boxer_id_2_info.boxer_firstname}
                  />
                  <Typography variant="body2" mt={1}>
                    {/* Add extra boxer information here */}
                    Weight: {bout.boxer_id_2_info.boxer_weight} lbs lbs
                  </Typography>
                </Box>
              </Card>
              
              {  user && JSON.parse(user).user_role === "abo_admin" && 
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                  <AddBoutJudgesModal bout_info={bout}/>
                  <AddBoutScoresModal bout_info={bout}/>
                  <AddBoutCommissionerModal bout_info={bout}/>
                </Box>
              }
              {/* Officials Information */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Typography variant="body2">
                  Judges: { 
                    bout.boutOfficials.judges.map((judge: any) => ( 
                      <Typography>
                        {judge?.info?.user_firstname} {judge?.info?.user_lastname}
                        <br />
                      </Typography>
                    )) 
                  }
                </Typography>
                <Typography variant="body2">
                Commissioners:<br /> {
                  bout.boutOfficials.commissioners.map((commissioner: any, index: number) => ( 
                    <Typography key={index} style={{textAlign: "center"}}>  
                      {commissioner?.info?.user_firstname} {commissioner?.info?.user_lastname}
                      <br />
                    </Typography>
                  ))
                }
                </Typography>
                <Typography variant="body2">Referee: {bout.referee || "TBA"}</Typography>
              </Box>
            </Box>
          ))
        }
      </Box>
    </>
  );
};