import React from "react";
import { ThemeProvider, CssBaseline, Grid } from "@mui/material";
import { PageUpdatesInfo, EventsInfo, AboNewsPaper, FeaturedFighters } from "../../components/HomePageCards/HomePageCards";
import { useDarkMode } from "../../hooks/useDarkMode";
import { lightTheme, darkTheme } from "../../themes/theme";
import { MenuBarDesktop, MenuBarMobile } from "../../components/MenuBar/MenuBar";
import { menuHook } from "../../hooks/menuHook";
import { useMediaQuery } from '@mui/material';
import { ViewBoxersTable } from "../../components/ViewBoxersTable/ViewBoxersTable";
import { ViewEventsTable } from "../../components/ViewEventsTable/ViewEventsTable";
import "./Home.css";

const Home: React.FC = () => {
  const [ darkMode, setDarkMode ]: any = useDarkMode();
  const [ menuItem, setMenuItem ] = menuHook();
  const theme = darkMode ? darkTheme : lightTheme;
  const isDesktop = useMediaQuery('(min-width:1024px)');
  const isMobile = useMediaQuery('(max-width:1023px)');

  return (
    <ThemeProvider theme={theme}>
      { isDesktop && <MenuBarDesktop /> }
      { isMobile && <MenuBarMobile /> }
      <div>
        <CssBaseline />
        <Grid container alignContent="center" justifyContent="left">
          { 
          menuItem === "MainHome" &&           
          <>
            <EventsInfo />
            <AboNewsPaper />
            <FeaturedFighters />
            <PageUpdatesInfo />
          </>
          }
          { menuItem === "ViewBoxersTable" && <ViewBoxersTable />}
          { menuItem === "ViewEventsTable" && <ViewEventsTable />}
        </Grid>
      </div>  
    </ThemeProvider >
  );
};

export default Home;
