import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Paper, TextField } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import { BoxerRecord } from "./ViewBoxerRecord";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import { EditBoxerModal } from '../EditBoxerModal/EditBoxerModal';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

export const ViewBoxersTable: React.FC = () => {
  const [rows, setRows]: any = React.useState([]);
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ loading, setLoading ] = React.useState<boolean | null>(null);
  const [ status, setStatus ] = React.useState<string | null>(null);
  const [searchTerm, setSearchTerm] = React.useState('');
  
  const getBoxers = async () => {
    try {
      setLoading(true);
      const response = await ABOClientApi.get_all_boxers();
      console.log(response);
      setRows(response);
      setLoading(false);
    } catch (error) {
      console.log("getBoxers Error", error);
      setLoading(false);
      setStatus("error");
    }
  };

  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY');
  };

  React.useEffect(() => {
    getBoxers();
  }, []);


  

  return (
    <> 
      {
        loading ? <div>Loading...</div> : 
        <>      
        <TextField 
          label="Search Boxers" 
          variant="outlined" 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          //fullWidth
          margin="normal"
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 550 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Boxer ABC ID</TableCell>
                <TableCell align="center">Boxer Image</TableCell>
                <TableCell align="left">First Name</TableCell>
                <TableCell align="left">Last Name</TableCell>
                <TableCell align="left">Sex</TableCell>
                <TableCell align="left">Date of Birth</TableCell>
                <TableCell align="left">Weight</TableCell>
                <TableCell align="left">Height</TableCell>
                <TableCell align="left">Reach</TableCell>
                <TableCell align="left">Stance</TableCell>
                <TableCell align="left">Division</TableCell>
                { user && JSON.parse(user).user_role === "abo_admin" && <TableCell align="left">Email Address</TableCell> }
                { user && JSON.parse(user).user_role === "abo_admin" && <TableCell align="left">Phone Number</TableCell> }
                <TableCell align="left">
                  <IconButton aria-label="refresh" onClick={getBoxers}>
                    <RefreshIcon />
                  </IconButton>                
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { rows && Array.isArray(rows) && rows.filter((row: any) => 
                  row.boxer_firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  row.boxer_lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  row.boxer_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  row.boxer_id.toString().includes(searchTerm)
                ).map((row: any) => (
                <TableRow
                  key={row.boxer_id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.boxer_id}
                  </TableCell>
                  <TableCell align="center"><img src={row.boxer_image} alt="image of boxer" width="100"/></TableCell>
                  <TableCell align="left">{row.boxer_firstname}</TableCell>
                  <TableCell align="left">{row.boxer_lastname}</TableCell>
                  <TableCell align="left">{row.boxer_gender}</TableCell>
                  <TableCell align="left">{formatDate(row.boxer_dob)}</TableCell>
                  <TableCell align="left">{row.boxer_weight}lbs</TableCell>
                  <TableCell align="left">{row.boxer_height}in</TableCell>
                  <TableCell align="left">{row.boxer_reach}in</TableCell>
                  <TableCell align="left">{row.boxer_stance}</TableCell>
                  <TableCell align="left">{row.boxer_division}</TableCell>
                  { user && JSON.parse(user).user_role === "abo_admin" && <TableCell align="left">{row.boxer_email}</TableCell> }
                  { user && JSON.parse(user).user_role === "abo_admin" && <TableCell align="left">{row.boxer_phone}</TableCell> }
                  <TableCell align="left">
                    <BoxerRecord boxerInfo={row}/> <br />
                    {user && JSON.parse(user).user_role === "abo_admin" && <EditBoxerModal boxer={row} getBoxers={getBoxers}/>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>
      }
    </>
  );
}

// { boxerInfo.boxer_id === bout.bout_winner ? "Win" : "Lost "} by {bout.bout_result}