import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Paper, Box, Typography, Modal } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import moment from 'moment';
import { EventsMoreInfoModal } from './EventsMoreInfoModal';

import "./ViewEventsTable.css";

export const ViewEventsTable: React.FC = () => {
  const [ rows, setRows ]: any = React.useState([]);
  const [ loading, setLoading ] = React.useState<boolean | null>(null);
  const [ status, setStatus ] = React.useState<string | null>(null);

  const getEvents = async () => {
    try {
      setLoading(true);
      const response = await ABOClientApi.get_all_events("", "");
      console.log(response);
      setRows(response);
      setLoading(false);
    } catch (error) {
      console.log("getEvents Error", error);
      setLoading(false);
      setStatus("error");
    }
  };

  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY HH:mm:ss');
  };

  React.useEffect(() => {
    getEvents();
  }, []);

  return (
    <>
    {
      loading && loading === true ? <div>Loading...</div>   
      :    
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Event Name</TableCell>
              <TableCell align="center">Event Image</TableCell>
              <TableCell align="left">Event Date</TableCell>
              <TableCell align="left">Event Venue</TableCell>
              <TableCell align="left">Event Address</TableCell>
              <TableCell align="left">Event City</TableCell>
              <TableCell align="left">Event State</TableCell>
              <TableCell align="left">Event Postalcode</TableCell>
              <TableCell align="left">Event Country</TableCell>
              <TableCell align="left">Event Type</TableCell>
              <TableCell align="left">Event Promoter</TableCell>
              <TableCell align="left">Event Text/Description</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row: any) => (
              <TableRow
                key={row.event_id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">{row.event_name}</TableCell>
                <TableCell align="center"><img src={row.event_image} alt="org logo" width="100"/></TableCell>
                <TableCell align="left">{formatDate(row.event_date)}</TableCell>
                <TableCell align="left">{row.event_venue}</TableCell>
                <TableCell align="left">{row.event_address}</TableCell>
                <TableCell align="left">{row.event_city}</TableCell>
                <TableCell align="left">{row.event_state}</TableCell>
                <TableCell align="left">{row.event_postalcode}</TableCell>
                <TableCell align="left">{row.event_country && JSON.parse(row.event_country).label}</TableCell>
                <TableCell align="left">{row.event_type}</TableCell>
                <TableCell align="left">{row.event_promoter}</TableCell>
                <TableCell align="left">{row.event_text}</TableCell>
                <TableCell align="left">
                  <EventsMoreInfoModal event_info={row} />
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    }
  </>
  );
}


