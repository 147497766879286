import React, { useState, useRef } from 'react';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdfjs/pdf.worker.min.mjs";

interface ImageSelectorProps {
    base64Image: string;
    setBase64Image: React.Dispatch<React.SetStateAction<string>>;
    boutScores: any;
    setBoutScores: React.Dispatch<React.SetStateAction<any>>;
}

export const ImageSelector: React.FC<ImageSelectorProps> = ({base64Image, setBase64Image, boutScores, setBoutScores }) => {
  const [fileType, setFileType] = useState<'image' | 'pdf'>('image');
  const [fileName, setFileName] = useState<string>("No file selected");
  const canvasRef = useRef<HTMLCanvasElement>(null);


  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === 'application/pdf') {
      setFileName(file.name);
      try {
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await pdfjsLib.getDocument(arrayBuffer).promise;
        const pages = await Promise.all(Array.from({length: pdf.numPages}, (_, i) => pdf.getPage(i + 1)));
        const firstPageViewport = pages[0].getViewport({ scale: 1 });
        
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) throw new Error("Could not get 2D context for canvas");

        canvas.width = firstPageViewport.width;
        canvas.height = pages.reduce((height, page) => height + page.getViewport({ scale: 1 }).height, 0);

        let currentY = 0;
        for(const page of pages) {
          const viewport = page.getViewport({ scale: 1 });
          const renderContext = {
            canvasContext: context,
            viewport: viewport
          };
          await page.render(renderContext).promise;
          context.translate(0, viewport.height);
          currentY += viewport.height;
        }

        const imgData = canvas.toDataURL('image/png');
        setBase64Image(imgData);
        setBoutScores({ ...boutScores, bout_score_card_image: imgData });
        
        context.setTransform(1, 0, 0, 1, 0, 0);
        canvas.remove();
      } catch (error) {
        console.error("Failed to load PDF", error);
        alert("Failed to process the PDF.");
      }
    } else {
      alert("Please select a PDF file.");
    }
  };


  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    if (file) {
        convertToBase64(file);
    }
  };

  const convertToBase64 = (file: File) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
          setBase64Image(reader.result as string);
          setBoutScores({ ...boutScores, bout_score_card_image: reader.result as string });
      };
  };

  return (
    <div>
      {
        base64Image && (
        <div>
          <img src={base64Image} alt="Selected" style={{ width: '200px' }} />
        </div>
        )
      }
      <FormLabel component="legend">Choose File Type</FormLabel>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="file-type"
          name="file-type"
          value={fileType}
          onChange={(event)=>setFileType(event.target.value as 'image' | 'pdf')}
        >
          <FormControlLabel value="image" control={<Radio />} label="Image" />
          <FormControlLabel value="pdf" control={<Radio />} label="PDF" />
        </RadioGroup>
      </FormControl> 
      <br />     
      {
        fileType === 'image' ? 
        ( 
          <input 
            type="file" 
            accept="image/*" 
            onChange={handleImageChange} 
          />
        ) : (
          <input 
            type="file" 
            accept="application/pdf" 
            onChange={handleFileChange} 
          />
        )
      }
      <canvas ref={canvasRef} style={{ display: 'none' }} /> {/* Keep this for potential future use */}
    </div>
  );
};

