import React, { useState } from 'react';
import { Modal, Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, FormControl, MenuItem, InputLabel, Select } from '@mui/material';
import { styled } from "@mui/material/";
import { AddBoutRecordCard } from "./AddBoutRecordCard";
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { menuHook } from "../../hooks/menuHook";
import moment from 'moment';
import { AddBoxerDialog } from "../AddBoxerCard/AddBoxerDialog";
import "./AddBoutRecordCard.css";

interface AddBoutRecordModal {
  // event_id: any;
};

export const AddBoutRecordModal: React.FC<AddBoutRecordModal> = ({}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
    getAllBoxers();
    getAllEvents();
  };
  const handleClose = () => setOpen(false);

  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ boutInfo, setBoutInfo] = React.useState({ 
    event_id: "", 
    boxer_id_1: "",
    bout_boxer_id_1_weight: "",
    boxer_id_2: "", 
    bout_boxer_id_2_weight: "",
    bout_date_time: "", 
    bout_planned_rounds: "", 
    bout_actual_rounds: "", 
    bout_weight_class: "", 
    bout_result: "", 
    bout_result_method: "",
    bout_type: "", 
    bout_boxer_id_winner: "",
    bout_title_fight: ""
  });
  const [ boutDate, setBoutDate ] = React.useState<any | null>(null);
  const [ boxers, setBoxers ] = React.useState<any[] | string >([]);
  const [ events, setEvent ] = React.useState<any[] | string >([]);
  const [ status, setStatus ] = React.useState("");
  const [ menuItem, setMenuItem ] = menuHook();

  const addBout = async () => {
    //console.log("user", user)
    console.log("bout info", boutInfo);
    console.log("jwt", jwt);
    const validate: any = await validateEventForm();
    console.log("validate", Object.keys(validate));
    Object.keys(validate).length > 0 && setStatus(Object.values(validate).join(', '));
    if(Object.keys(validate).length > 0)return;
    try {
      setStatus("Adding Bout...");
      const response = await ABOClientApi.add_bout_record(
        jwt, 
        boutInfo.event_id.toString(), 
        boutInfo.boxer_id_1,
        boutInfo.bout_boxer_id_1_weight,
        boutInfo.boxer_id_2,
        boutInfo.bout_boxer_id_2_weight,
        boutInfo.bout_date_time, 
        boutInfo.bout_planned_rounds, 
        boutInfo.bout_actual_rounds, 
        boutInfo.bout_weight_class, 
        boutInfo.bout_result, 
        boutInfo.bout_result_method, 
        boutInfo.bout_type,
        boutInfo.bout_boxer_id_winner,
        boutInfo.bout_title_fight
      );
      console.log(response);
      response === "ok" && setStatus(`Bout record added.`)
    } catch (error) {
      console.log("addBout Error", error);
      setStatus("Add Bout Error");
    }
  };  

  const handleInputChange = (field: string, event: any) => {
    //console.log("input change ", field, event )
    setBoutInfo({ ...boutInfo, [field]: event.target.value });
  };

  const handleDateChange = (newValue: any) => {
    const dateLinux = newValue.format("X");
    // console.log("date change", dateLinux)
    // console.log("date change", new Date(dateLinux*1000).toLocaleString());
    setBoutDate(newValue);
    setBoutInfo({ ...boutInfo, bout_date_time: dateLinux });
  };

  const getAllBoxers = async () => {
    try {
      const response: string | any[] = await ABOClientApi.get_all_boxers();
      console.log(response);
      setBoxers(response);
    } catch (error) {
      console.log("getBoxers Error", error);
    }
  };
  const getAllEvents = async () => {
    try {
      const response: string | any[] = await ABOClientApi.get_all_events("", "true");
      console.log(response);
      setEvent(response);
    } catch (error) {
      console.log("getEvents Error", error);
      setStatus("Error getting events");
    }
  };

  const SelectBoxer1 = () => {
    return (
      <FormControl sx={{ width: 200, margin: "5px", textAlign: "left", float: "left" }}>
        <InputLabel id="SelectBoxer1_label">Select Boxer 1</InputLabel>
        <Select
          labelId="SelectBoxer1_label"
          id="SelectBoxer1"
          value={boutInfo.boxer_id_1}
          label="Boxer 1"
          onChange={(event: any) => handleInputChange( "boxer_id_1", event )}
        >
          {
            boxers && Array.isArray(boxers) && boxers.map(( boxer: any ) => (
              <MenuItem value={boxer.boxer_id.toString()}>{boxer.boxer_firstname + " " + boxer.boxer_lastname}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };
  const SelectBoxer2 = () => {
    return (
      <FormControl sx={{ width: 200, margin: "5px", textAlign: "left", float: "left" }}>
        <InputLabel id="SelectBoxer2_label">Select Boxer 2</InputLabel>
        <Select
          labelId="SelectBoxer2_label"
          id="SelectBoxer2"
          value={boutInfo.boxer_id_2}
          label="Boxer 1"
          onChange={(event: any) => handleInputChange("boxer_id_2", event)}
        >
          {
            boxers && Array.isArray(boxers) && boxers.map(( boxer: any ) => (
              <MenuItem value={boxer.boxer_id.toString()}>{boxer.boxer_firstname + " " + boxer.boxer_lastname}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };

  const SelectWinner = () => {
    return (
      <FormControl sx={{ width: 200, margin: "5px", textAlign: "left", float: "left" }}>
        <InputLabel id="SelectWinner_label">Select Winner</InputLabel>
        <Select
          labelId="SelectWinner_label"
          id="SelectWInner"
          value={boutInfo.bout_boxer_id_winner}
          label="Select Winner"
          onChange={(event: any) => handleInputChange("bout_boxer_id_winner", event)}
        >
          <MenuItem value={boutInfo.boxer_id_1.toString()}>Boxer 1</MenuItem>
          <MenuItem value={boutInfo.boxer_id_2.toString()}>Boxer 2</MenuItem>
        </Select>
      </FormControl>
    );
  };
  const SelectEvent = ( ) => {
    return (
      <FormControl sx={{ width: 200, margin: "5px", textAlign: "left", float: "left" }}>
        <InputLabel id="SelectOrganization_label">Select Bout Event</InputLabel>
        <Select
          labelId="SelectOrganization_label"
          id="SelectOrganization"
          value={boutInfo.event_id}
          label="Bout Event"
          onChange={(event: any) => handleInputChange("event_id", event)}
        >
          {
            events && Array.isArray(events) && events.map(( event: any ) => (
              <MenuItem value={event.event_id}>{event.event_name} - {formatDate(event.event_date)}</MenuItem>
            ))
          }
           <MenuItem value="add_event">Add Event</MenuItem>
        </Select>
      </FormControl>
    );
  };
  const SelectWeightClass = () => {
    return (
        <FormControl sx={{ width: 200, margin: "5px", float: "left", textAlign: "left" }}>
          <InputLabel id="select_division_label">Bout Weight Class</InputLabel>
          <Select
            labelId="select_division_label"
            id="select_division"
            value={boutInfo.bout_weight_class}
            label="Select Division"
            onChange={(event: any) => handleInputChange("bout_weight_class", event)}
          >
            <MenuItem value={"Light Flyweight"}>Light Flyweight</MenuItem>
            <MenuItem value={"Flyweight"}>Flyweight</MenuItem>
            <MenuItem value={"Super Flyweight"}>Super Flyweight</MenuItem>
            <MenuItem value={"Bantamweigh"}>Bantamweight</MenuItem>
            <MenuItem value={"Super Bantamweight"}>Super Bantamweight</MenuItem>
            <MenuItem value={"Featherweight"}>Featherweight</MenuItem>
            <MenuItem value={"Super featherweight"}>Super featherweight</MenuItem>
            <MenuItem value={"Lightweight"}>Lightweight</MenuItem>
            <MenuItem value={"Super Lightweight"}>Super Lightweight</MenuItem>
            <MenuItem value={"Welterweight"}>Welterweight</MenuItem>
            <MenuItem value={"Super Welterweight"}>Super Welterweight</MenuItem>
            <MenuItem value={"Middleweight"}>Middleweight</MenuItem>
            <MenuItem value={"Super Middleweight"}>Super Middleweight</MenuItem>
            <MenuItem value={"Light Heavyweight"}>Light Heavyweight</MenuItem>
            <MenuItem value={"Cruiserweight"}>Cruiserweight</MenuItem>
            <MenuItem value={"Heavyweight"}>Heavyweight</MenuItem>
          </Select>
        </FormControl>
    );
  };
  const SelectBoutType = () => {
    return (
        <FormControl sx={{ width: 200, margin: "5px", float: "left", textAlign: "left" }}>
          <InputLabel id="select_bout_type_label">Bout Type</InputLabel>
          <Select
            labelId="select_bout_type_label"
            id="select_bout_type"
            value={boutInfo.bout_type}
            label="Select Result"
            onChange={(event: any) => handleInputChange("bout_type", event)}
          >
            <MenuItem value={"Professional"}>Professional</MenuItem>
            <MenuItem value={"Amateur"}>Amateur</MenuItem>
            <MenuItem value={"Exhibition"}>Exhibition</MenuItem>
            <MenuItem value={"White Collar"}>White Collar</MenuItem>
            <MenuItem value={"Smoker"}>Smoker</MenuItem>
          </Select>
        </FormControl>
    );
  };
  const SelectBoutResult = () => {
    return (
        <FormControl sx={{ width: 200, margin: "5px", float: "left", textAlign: "left" }}>
          <InputLabel id="select_result_label">Bout Result</InputLabel>
          <Select
            labelId="select_result_label"
            id="select_result"
            value={boutInfo.bout_result}
            label="Select Result"
            onChange={(event: any) => handleInputChange("bout_result", event)}
          >
            <MenuItem value={"Knockout(KO)"}>Knockout(KO)</MenuItem>
            <MenuItem value={"Technical Knockout(TKO)"}>Technical Knockout(TKO)</MenuItem>
            <MenuItem value={"Unanimous Decision(UD)"}>Unanimous Decision(UD)</MenuItem>
            <MenuItem value={"Majority Decision(MD)"}>Majority Decision(MD)</MenuItem>
            <MenuItem value={"Split Decision(SD)"}>Split Decision(SD)</MenuItem>
            <MenuItem value={"Technical Decision(TD)"}>Technical Decision(TD)</MenuItem>
            <MenuItem value={"Unanimous Draw(UD)"}>Unanimous Draw(UD)</MenuItem>
            <MenuItem value={"Majority Draw(MD)"}>Majority Draw(MD)</MenuItem>
            <MenuItem value={"Split Draw(SD)"}>Split Draw(SD)</MenuItem>
            <MenuItem value={"No Contest(NC)"}>No Contest(NC)</MenuItem>
            <MenuItem value={"Disqualification(DQ)"}>Disqualification(DQ)</MenuItem>
          </Select>
        </FormControl>
    );
  };
  const SelectBoutTitle = () => {
    return (
        <FormControl sx={{ width: 200, margin: "5px", textAlign: "left" }}>
          <InputLabel id="select_bout_title_label">Bout Title</InputLabel>
          <Select
            labelId="select_bout_title_label"
            id="select_bout_title"
            value={boutInfo.bout_title_fight}
            label="Bout Title"
            onChange={(event: any) => handleInputChange("bout_title_fight", event)}
          >
            <MenuItem value={""}>No</MenuItem>
            <MenuItem value={"ABO"}>ABO</MenuItem>
          </Select>
        </FormControl>
    );
  };
  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY HH:mm:ss');
  };

  const validateEventForm: any = async () => {
    // !eventInfo.organization_email.trim() && (errors.organization_email = 'Email is required') || !/\S+@\S+\.\S+/.test(orgInfo.organization_email) && (errors.organization_email = 'Email address is invalid');
    // !eventInfo.organization_phone.trim() && (errors.organization_phone = 'Phone number is required') || !/^\d{10}$\S+\-/.test(orgInfo.organization_phone) && (errors.organization_phone = 'Phone number is invalid');    
    const errors: any = {};
    // !boutInfo.event_id.toString().trim() && (errors.event_id = 'Event Name is required');
    !boutInfo.boxer_id_1.trim() && (errors.boxer_id_1 = 'Boxer 1 is required');
    !boutInfo.boxer_id_2.trim() && (errors.boxer_id_2 = 'Boxer 2 is required');
    // !boutInfo.bout_date_time.trim() && (errors.bout_date_time = 'Bout date is required');
    // !boutInfo.bout_planned_rounds.trim() && (errors.bout_planned_rounds = 'Bout rounds is required');
    // !boutInfo.bout_actual_rounds.trim() && (errors.bout_actual_rounds = 'Bout rounds method is required');
    // !boutInfo.bout_weight_class.trim() && (errors.bout_weight_class = 'Bout weight class rounds is required');
    // !boutInfo.bout_result.trim() && (errors.bout_result = 'Bout result rounds is required');
    // !boutInfo.bout_result_method.trim() && (errors.bout_result_method = 'Bout result method is required');
    // !boutInfo.bout_type.trim() && (errors.bout_type = 'Bout type class is required');
    return errors;
  };

  // Define a styled Box component with our sporty design
  const SportyBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '20px',
    borderRadius: '10px',
    border: '3px solid transparent',
    backgroundImage: `linear-gradient(${theme.palette.background.default}, ${theme.palette.background.default}), radial-gradient(circle at top left, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    overflow: 'hidden',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: -3,
      right: -3,
      bottom: -3,
      left: -3,
      zIndex: -1,
      borderRadius: 'inherit',
      background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.secondary.light})`,
      transition: 'transform 0.2s ease',
    },

    '&:hover::before': {
      transform: 'scale(1.05) rotate(2deg)', // Slight scale and tilt for more dynamic interaction
    }
  }));

  return (
    <>
      <Button variant='contained' onClick={handleOpen}>Add Bout</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '500px',
            borderRadius: '16px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
          }
        }}
      >
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            Add Bout Record
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <form style={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
            <SelectEvent />
            {/* Boxer 1 and Weight */}
            <div style={{ display: 'flex', gap: '10px', gridColumn: '1 / 3' }}>
              <SelectBoxer1 />
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Boxer 1 Weight(LBS)"
                variant="outlined"
                value={ boutInfo.bout_boxer_id_1_weight }
                onChange={(event: any) => handleInputChange("bout_boxer_id_1_weight", event)}
              />
            </div>

            {/* Boxer 2 and Weight */}
            <div style={{ display: 'flex', gap: '10px', gridColumn: '1 / 3' }}>
              <SelectBoxer2 />
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Boxer 2 Weight(LBS)"
                variant="outlined"
                value={ boutInfo.bout_boxer_id_2_weight }
                onChange={(event: any) => handleInputChange("bout_boxer_id_2_weight", event)}
              />
            </div>

            <div style={{ width: "200px", margin: "5px", gridColumn: '1 / 3' }}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  format="DD/MM/YYYY hh:mm a"
                  label="Bout Date/Time"
                  value={boutDate}
                  onChange={(newValue: any) => {
                    handleDateChange(newValue)
                  }}
                />
              </LocalizationProvider>
            </div>

            <TextField
              style={{ width: "200px", margin: "5px" }}
              type="text"
              label="Bout Planned Rounds"
              variant="outlined"
              value={ boutInfo.bout_planned_rounds }
              onChange={(event: any) => handleInputChange("bout_planned_rounds", event)}
            />

            <TextField
              style={{ width: "200px", margin: "5px"}}
              type="text"
              label="Bout Actual Rounds"
              variant="outlined"
              value={ boutInfo.bout_actual_rounds }
              onChange={(event: any) => handleInputChange("bout_actual_rounds", event)}
            />

            <SelectWeightClass />
            <SelectBoutResult />

            <TextField
              style={{ width: "200px", margin: "5px", gridColumn: '1 / 3' }}
              type="text"
              label="Bout Result Method Description."
              variant="outlined"
              value={ boutInfo.bout_result_method }
              onChange={(event: any) => handleInputChange("bout_result_method", event)}
            />

            <SelectBoutType />
            <SelectWinner />
            <SelectBoutTitle />
          </form>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button variant="contained" color="primary" onClick={ ()=>addBout() }>
              Add Bout
            </Button>
            <Button color="secondary" variant="contained" onClick={ ()=>handleClose() }>
              Close
            </Button> 
          </div>
          { status && <div><Typography variant="h5">{status}</Typography></div> }
        </DialogActions>
      </Dialog>
    </>
  );
};