import * as React from "react";
import { Typography, TextField, Button } from "@mui/material/";
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import CountrySelect from "./CountrySelect";
import { ImageSelector } from "./imageTools"

export const AddOrganizationCard = () => {
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ base64Image, setBase64Image ] = React.useState<string>('');
  const [ orgInfo, setOrgInfo] = React.useState({ 
    organization_name: "", 
    organization_shortname: "",
    organization_email: "",
    organization_phone: "",
    organization_address: "",
    organization_city: "", 
    organization_state: "", 
    organization_postalcode: "", 
    organization_country: "", 
    org_image: base64Image
  });
  const [ status, setStatus ] = React.useState("");


  const addOrg = async () => {
    console.log("org info", orgInfo);
    console.log("jwt", jwt);
    const validate: any = await validateOrgInfo();
    console.log("validate", Object.keys(validate).length);
    Object.keys(validate).length > 0 && setStatus(Object.values(validate).join(', '));
    if(Object.keys(validate).length > 0)return;
    try {
      setStatus("Adding Organization...");
      const response = await ABOClientApi.create_organization(
        jwt, 
        orgInfo.organization_name, 
        orgInfo.organization_shortname, 
        orgInfo.organization_email, 
        orgInfo.organization_phone, 
        orgInfo.organization_address, 
        orgInfo.organization_city, 
        orgInfo.organization_state, 
        orgInfo.organization_postalcode, 
        orgInfo.organization_country, 
        orgInfo.org_image
      );
      setStatus(response);
      console.log(response);
      response === "ok" && setStatus(`Organization ${orgInfo.organization_name} Added.`)
    } catch (error) {
      console.log("registerOrg Error", error);
    }
  };  

  const handleInputChange = (field: string, event: any) => {
    //console.log("input change ", field, event )
    setOrgInfo({ ...orgInfo, [field]: event.target.value });
  };
  const handleCountryOriginChange = (event: any) => {
    console.log("country change", event)
    setOrgInfo({ ...orgInfo, ["organization_country"]: JSON.stringify(event) })
  };
  const validateOrgInfo: any = async () => {
    const errors: any = {};
    !orgInfo.organization_name.trim() && (errors.organization_name = 'Organization Name is required');
    !orgInfo.organization_shortname.trim() && (errors.organization_shortname = 'Organization Short Name is required');
    !orgInfo.organization_email.trim() && (errors.organization_email = 'Email is required') || !/\S+@\S+\.\S+/.test(orgInfo.organization_email) && (errors.organization_email = 'Email address is invalid');
    //!orgInfo.organization_phone.trim() && (errors.organization_phone = 'Phone number is required') || !/^\d{10}$\S+\-/.test(orgInfo.organization_phone) && (errors.organization_phone = 'Phone number is invalid');
    // !orgInfo.organization_address.trim() && (errors.organization_address = 'Address is required');
    // !orgInfo.organization_city.trim() && (errors.organization_city = 'City is required');
    // !orgInfo.organization_state.trim() && (errors.organization_state = 'State is required');
    // !orgInfo.organization_postalcode.trim() && (errors.organization_postalcode = 'Postal Code is required');
    // !orgInfo.organization_country.trim() && (errors.organization_country = 'Country is required');
    return errors;
  };

  return (
    <>
    <div style={{maxWidth: 800, textAlign: "center"}}>
      <Typography variant="h5">Add New Org</Typography>
      <hr />
      <form>
        <Typography>Select Organization Logo</Typography>
        <ImageSelector
          base64Image={base64Image}
          setBase64Image={setBase64Image}
          orgInfo={orgInfo}
          setOrgInfo={setOrgInfo}
        />
        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Organization Name"
          variant="outlined"
          value={orgInfo.organization_name}
          onChange={(event: any) => handleInputChange("organization_name", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Organization Short Name"
          variant="outlined"
          value={orgInfo.organization_shortname}
          onChange={(event: any) => handleInputChange("organization_shortname", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Organization Email"
          variant="outlined"
          value={orgInfo.organization_email}
          onChange={(event: any) => handleInputChange("organization_email", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Organization Phone"
          variant="outlined"
          value={orgInfo.organization_phone}
          onChange={(event: any) => handleInputChange("organization_phone", event)}
        />
        
        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Organization Address"
          variant="outlined"
          value={orgInfo.organization_address}
          onChange={(event: any) => handleInputChange("organization_address", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Organization City"
          variant="outlined"
          value={orgInfo.organization_city}
          onChange={(event: any) => handleInputChange("organization_city", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Organization State"
          variant="outlined"
          value={orgInfo.organization_state}
          onChange={(event: any) => handleInputChange("organization_state", event)}
        />

        <TextField
          style={{ width: "200px", margin: "5px", float: "left" }}
          type="text"
          label="Organization Postal Code"
          variant="outlined"
          value={orgInfo.organization_postalcode}
          onChange={(event: any) => handleInputChange("organization_postalcode", event)}
        />

        <CountrySelect handleInputChange={handleCountryOriginChange} label="Organization Country" />
      </form>
    </div>
    <div>
      <Button variant="contained" color="primary" onClick={ ()=>addOrg() }>
        Add Organization
      </Button>
    </div>
    { status && <div><Typography variant="h5">{status}</Typography></div> }
    </>
  );
}