import React, { useState } from 'react';
import { Modal, Box, Button, Typography, FormControl, InputLabel, Select, MenuItem,  } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import { jwtHook } from "../../hooks/userHooks";

interface AddEventJudgesProps {
  event_info: any;
  getEventJudges: any;
}

export const AddEventJudgesModal: React.FC<AddEventJudgesProps> = ({ event_info, getEventJudges }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [ judges, setJudges ] = useState< string | any[]>([]); // [ { id: 1, name: 'John Doe' }, { id: 2, name: 'Jane Doe' }
  const [ jwt, setJWT ] = jwtHook<any>();
  const [ status, setStatus ] = useState("");
  const [ input, setInput ] = useState<any>({
    event_id: event_info.event_id,
    user_id: ""
  }); // { event_id: 1, user_id: 1 }


  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: "center"
  };

  const openModal = () => {
    setOpen(true);
    console.log(event_info);
    getJudges();
  };

  const addJudge = async () => {
    console.log(input);
    console.log("jwt", jwt);
    if(input.user_id === ""){{setStatus("Select a Judge"); return}}
    try{
      const response = await ABOClientApi.add_event_judge( 
        jwt, 
        input.event_id.toString(), 
        input.user_id.toString()
      );
      console.log(response);
      response === "ok" && setStatus(`judge added to event ${event_info.event_name}`);
      getEventJudges();
    }catch(error){
      console.log("addJudge Error", error);
    };
  };

  const getJudges = async() =>{
    const judgesRes: string | any[] = await ABOClientApi.get_users_by_role( "", "judge" );
    console.log("judgesRes", judgesRes);
    setJudges(judgesRes);
  };

  const SelectJudge: React.FC = ( ) => {
    return (
      <FormControl sx={{ width: 200, margin: "5px"}}>
        <InputLabel id="SelectJudge_label">Select Judge</InputLabel>
        <Select
          labelId="SelectJudge_label"
          id="SelectJudge"
          value={input.user_id}
          label="Judges"
          onChange={(event: any) => setInput({ ...input, user_id: event.target.value })}
        >
          {
            judges && Array.isArray(judges) && judges.map(( judge: any ) => (
              <MenuItem value={judge.user_id}>{judge.user_firstname} {judge.user_lastname}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };

  return (
    <>
      <Button onClick={openModal}>Add Judge</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={style}
      >
        <>
        <Box >
          <Typography id="modal-title" variant="h6" component="h2">
            Add Judge
          </Typography>
        </Box>
        <hr />
        <Box>
          <SelectJudge />
        </Box>
        <Box >
          <Button variant="contained" color="primary" onClick={ ()=>addJudge() }>
            Add Judge
          </Button>
        </Box>
        <Box>
          <Typography variant="body2" color="text.secondary">
            {status}
          </Typography>
        </Box>
        </>
      </Modal>
    </>
  );
};

const AddEventCommissionersForm = () => {};