import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography,  TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { AddBoxerCard } from './AddBoxerCard';
import { ABOClientApi } from "../../api/ABOApi";
import CountrySelect from "./CountrySelect";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import { ImageSelector } from "./imageTools"
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import "./AddBoxerCard.css";

interface AddBoxerDialogProps {

};

export const AddBoxerDialog: React.FC<AddBoxerDialogProps> = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => { 
    setOpen(true);
    getOrgs();
  };
  const handleClose = () => setOpen(false);
  const [ selectedImage, setSelectedImage ] = React.useState<any>("");
  const [ base64Image, setBase64Image ] = React.useState<string>('');
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ status, setStatus ]: any = React.useState();
  const [ dobValue, setDobValue ] = React.useState<any | null>(null);
  const [ orgs, setOrgs ] = React.useState<any>([]);
  
  const [ boxerInfo, setBoxerInfo] = React.useState<any>({
    boxer_id: "", 
    boxer_firstname: "", 
    boxer_lastname: "", 
    boxer_gender: "", 
    boxer_dob: "", 
    boxer_weight:"", 
    boxer_height:"",  
    boxer_reach:"", 
    boxer_stance: "", 
    boxer_division: "", 
    boxer_city_origin: "", 
    boxer_country_origin: "", 
    boxer_city_residence: "", 
    boxer_country_residence: "", 
    organization_id: "", 
    boxer_email: "", 
    boxer_phone: "", 
    boxerImage: base64Image 
  });


  const getOrgs = async () => {
    try {
      const response = await ABOClientApi.get_all_organizations();
      console.log(response);
      setOrgs(response);
    } catch (error) {
      console.log("getOrgs Error", error);
    }
  };

  const clearForm = () => {
    const initialState = {
      boxer_id: "", 
      boxer_firstname: "", 
      boxer_lastname: "", 
      boxer_gender: "", 
      boxer_dob: "", 
      boxer_weight: "", 
      boxer_height: "",  
      boxer_reach: "", 
      boxer_stance: "", 
      boxer_division: "", 
      boxer_city_origin: "", 
      boxer_country_origin: "", 
      boxer_city_residence: "", 
      boxer_country_residence: "", 
      organization_id: "", 
      boxer_email: "", 
      boxer_phone: "", 
      boxerImage: base64Image // Assuming base64Image is defined elsewhere or set it to "" if it should also start empty
    };
    setStatus();
    setBase64Image("");
    setBoxerInfo(initialState);
  };

  const addBoxer = async () => {
    // console.log("base64Image", base64Image);
    // console.log(typeof(base64Image));
    console.log("jwt", jwt);
    console.log(boxerInfo);
    const validate: any = await validateBoxerInfo();
    console.log("validate", Object.keys(validate).length);
    Object.keys(validate).length > 0 && setStatus(Object.values(validate).join(', '));
    if(Object.keys(validate).length > 0)return;
    try {
      setStatus("Adding Boxer...");
      
      const response = await ABOClientApi.add_boxer(
        jwt,
        boxerInfo.boxer_id, 
        boxerInfo.boxer_firstname,
        boxerInfo.boxer_lastname, 
        boxerInfo.boxer_gender,
        boxerInfo.boxer_dob,
        boxerInfo.boxer_weight,
        boxerInfo.boxer_height,
        boxerInfo.boxer_reach,
        boxerInfo.boxer_stance,
        boxerInfo.boxer_division,
        boxerInfo.boxer_city_origin,
        boxerInfo.boxer_country_origin,
        boxerInfo.boxer_city_residence,
        boxerInfo.boxer_country_residence,
        boxerInfo.organization_id,
        boxerInfo.boxer_email,
        boxerInfo.boxer_phone,
        boxerInfo.boxerImage
      );
      console.log(response);
      setStatus(response);
      response === "ok" && setStatus(`Boxer ${boxerInfo.boxer_firstname} ${boxerInfo.boxer_lastname} Added.`)
      console.log("sending", boxerInfo);
    } catch (error) {
      console.log("addBoxer Error", error);
    }
  };

  const handleInputChange = (field: string, event: any) => {
    // console.log("input change ", field, event )
    // console.log("input change ", event.target.value)
    // setBoxerInfo({ ...boxerInfo, [field]: event.target.value });
    setBoxerInfo({ ...boxerInfo, [field]: event.target.value })
  };

  const handleCountryOriginChange = (event: any) => {
    console.log("country change", event)
    setBoxerInfo({ ...boxerInfo, ["boxer_country_origin"]: JSON.stringify(event) });
  };

  const handleCountryResidenceChange = (event: any) => {
    setBoxerInfo({ ...boxerInfo, ["boxer_country_residence"]: JSON.stringify(event) });
  }; 

  const handleDateChange = (newValue: any) => {
    setDobValue(newValue);
    setBoxerInfo({ ...boxerInfo, boxer_dob: newValue.format("X") });
  };

  const SelectDivision = () => {
    return (
        <FormControl sx={{ width: 200, margin: "5px", float: "left", textAlign: "left" }}>
          <InputLabel id="select_division_label">Divison</InputLabel>
          <Select
            labelId="select_division_label"
            id="select_division"
            value={boxerInfo.boxer_division}
            label="Select Division"
            onChange={(event: any) => handleInputChange("boxer_division", event)}
          >
            <MenuItem value={"Light Flyweight"}>Light Flyweight</MenuItem>
            <MenuItem value={"Flyweight"}>Flyweight</MenuItem>
            <MenuItem value={"Super Flyweight"}>Super Flyweight</MenuItem>
            <MenuItem value={"Bantamweigh"}>Bantamweight</MenuItem>
            <MenuItem value={"Super Bantamweight"}>Super Bantamweight</MenuItem>
            <MenuItem value={"Featherweight"}>Featherweight</MenuItem>
            <MenuItem value={"Super featherweight"}>Super featherweight</MenuItem>
            <MenuItem value={"Lightweight"}>Lightweight</MenuItem>
            <MenuItem value={"Super Lightweight"}>Super Lightweight</MenuItem>
            <MenuItem value={"Welterweight"}>Welterweight</MenuItem>
            <MenuItem value={"Super Welterweight"}>Super Welterweight</MenuItem>
            <MenuItem value={"Middleweight"}>Middleweight</MenuItem>
            <MenuItem value={"Super Middleweight"}>Super Middleweight</MenuItem>
            <MenuItem value={"Light Heavyweight"}>Light Heavyweight</MenuItem>
            <MenuItem value={"Cruiserweight"}>Cruiserweight</MenuItem>
            <MenuItem value={"Heavyweight"}>Heavyweight</MenuItem>
          </Select>
        </FormControl>
    );
  };

  const SelectGender = () => {
    return(
      <FormControl sx={{ width: 200, float: "left", margin: "5px", textAlign: "left" }}>
        <InputLabel id="select_gender_label">Gender</InputLabel>
        <Select
          labelId="select_gender_label"
          id="select_gender"
          value={boxerInfo.boxer_gender}
          label="Select Gender"
          onChange={(event: any) => handleInputChange("boxer_gender", event)}
        >
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const SelectStance = () => {
    return(
      <FormControl sx={{ width: 200, float: "left", margin: "5px", textAlign: "left" }}>
        <InputLabel id="select_stance_label">Stance</InputLabel>
        <Select
          labelId="select_stance_label"
          id="select_stance"
          value={boxerInfo.boxer_stance}
          label="Select Stance"
          onChange={(event: any) => handleInputChange("boxer_stance", event)}
        >
          <MenuItem value="orthodox">Orthodox</MenuItem>
          <MenuItem value="southpaw">Southpaw</MenuItem>
        </Select>
      </FormControl>
    );
  };
  
  const SelectOrg = () => {
    return(
      <FormControl sx={{ width: 200, float: "left", margin: "5px", textAlign: "left" }}>
        <InputLabel id="select_org_label">Organization</InputLabel>
        <Select
          labelId="select_org_label"
          id="select_org"
          value={boxerInfo.organization_id}
          label="Select Organization"
          onChange={(event: any) => handleInputChange("organization_id", event)}
        >
          {
            orgs && orgs.map((org: any) => (
              <MenuItem value={org.organization_id.toString()}>{org.organization_name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };

  const validateBoxerInfo: any = async () => {
    const errors: any = {};
    !boxerInfo.boxer_id.trim() && (errors.boxer_id = 'Boxer ID is reuiqred');
    !boxerInfo.boxer_firstname.trim() && (errors.boxer_firstname = 'First name is required');
    !boxerInfo.boxer_lastname.trim() && (errors.boxer_lastname = 'Last name is required');
    !boxerInfo.boxer_gender.trim() && (errors.boxer_gender = 'Gender is required');
    !boxerInfo.boxer_dob.trim() && (errors.boxer_dob = 'Date of birth is required');
    !boxerInfo.boxer_weight.trim() && (errors.boxer_weight = 'Weight is required');
    !boxerInfo.boxer_height.trim() && (errors.boxer_height = 'Height is required');
    !boxerInfo.boxer_reach.trim() && (errors.boxer_reach = 'Reach is required');
    !boxerInfo.boxer_stance.trim() && (errors.boxer_stance = 'Stance is required');
    !boxerInfo.boxer_division.trim() && (errors.boxer_division = 'Division is required');
    // !boxerInfo.boxer_city_origin.trim() && (errors.boxer_city_origin = 'City of origin is required');
    // !boxerInfo.boxer_country_origin.trim() && (errors.boxer_country_origin = 'Country of origin is required');
    // !boxerInfo.boxer_city_residence.trim() && (errors.boxer_city_residence = 'City of residence is required');
    // !boxerInfo.boxer_country_residence.trim() && (errors.boxer_country_residence = 'Country of residence is required');
    // !boxerInfo.organization_id.trim() && (errors.organization_id = 'Organization name is required');
    // !boxerInfo.boxer_email.trim() && (errors.boxer_email = 'Email is required') ||
    // !/\S+@\S+\.\S+/.test(boxerInfo.boxer_email) && (errors.boxer_email = 'Email address is invalid');
    // !boxerInfo.boxer_phone.trim() && (errors.boxer_phone = 'Phone number is required') ||
    // !/^\d{10}$/.test(boxerInfo.boxer_phone) && (errors.boxer_phone = 'Phone number is invalid');
    // !boxerInfo.boxerImage.trim() && (errors.boxerImage = 'Boxer Image is required');
    return errors;
  };

  return (
    <>
      <Button onClick={handleOpen}>Add Boxer</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '500px',
            borderRadius: '16px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
          }
        }}
      >
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            Add Boxer
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <form style={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
              <ImageSelector
                selectedImage={selectedImage}
                setSelectedImage={setSelectedImage}
                base64Image={base64Image}
                setBase64Image={setBase64Image}
                boxerInfo={boxerInfo}
                setBoxerInfo={setBoxerInfo}
              />
              
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Boxer ID"
                variant="outlined"
                value={boxerInfo.boxer_id}
                onChange={(event: any) => handleInputChange("boxer_id", event)}
              />
              
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="First Name"
                variant="outlined"
                value={boxerInfo.boxer_firstname}
                onChange={(event: any) => handleInputChange("boxer_firstname", event)}
              />
              
              <TextField
                style={{ width: "200px", margin: "5px"}}
                type="text"
                label="Last Name"
                variant="outlined"
                value={boxerInfo.boxer_lastname}
                onChange={(event: any) => handleInputChange("boxer_lastname", event)}
              />
              
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Email Address"
                variant="outlined"
                value={boxerInfo.boxer_email}
                onChange={(event: any) => handleInputChange("boxer_email", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Phone Number(123-456-7890)"
                variant="outlined"
                value={boxerInfo.boxer_phone}
                onChange={(event: any) => handleInputChange("boxer_phone", event)}
              />

              <SelectGender />

              <div style={{ width: "200px", margin: "5px" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="Boxer DOB"
                    value={dobValue}
                    onChange={(newValue: any) => {
                      handleDateChange(newValue)
                    }}
                  />
                </LocalizationProvider>
              </div>

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="number"
                label="Boxer Weight(lbs)"
                variant="outlined"
                value={boxerInfo.boxer_weight}
                onChange={(event: any) => handleInputChange("boxer_weight", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="number"
                label="Boxer Height(inches)"
                variant="outlined"
                value={boxerInfo.boxer_height}
                onChange={(event: any) => handleInputChange("boxer_height", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="number"
                label="Boxer Reach(inches)"
                variant="outlined"
                value={boxerInfo.boxer_reach}
                onChange={(event: any) => handleInputChange("boxer_reach", event)}
              />
              <SelectStance />

              <SelectDivision />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Boxer City Origin"
                variant="outlined"
                value={boxerInfo.boxer_city_origin}
                onChange={(event: any) => handleInputChange("boxer_city_origin", event)}
              />       
              
              <CountrySelect handleInputChange={handleCountryOriginChange} label="Country Of Origin" />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Boxer City of Residence"
                variant="outlined"
                value={boxerInfo.boxer_city_residence}
                onChange={(event: any) => handleInputChange("boxer_city_residence", event)}
              />

              <CountrySelect handleInputChange={handleCountryResidenceChange} label="Country Of Residence" />

              <SelectOrg />
            </form>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button variant="contained" color="primary" onClick={ ()=>addBoxer() }>
              Add Boxer
            </Button>
            <Button variant="contained" color="secondary" onClick={ ()=>clearForm() }>
              Clear Form
            </Button>
            <Button color="secondary" variant="contained" onClick={ ()=>handleClose() }>
              Close
            </Button>
          </div>
          {status && <div>{status}</div>}
        </DialogActions>
      </Dialog>
    </>
  );
};