import React from 'react';
import { Box, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { userLoginHook } from "../../hooks/userHooks";
import { AddEventJudgesModal } from '../AddEventJudges/AddEventJudges';
import { ABOClientApi } from "../../api/ABOApi";

interface JudgeInfo {
  user_id: string;
  user_firstname: string;
  user_lastname: string;
  user_image: string;
  info: any;
  // Add other Commissioner properties as needed
}

interface ViewEventJudgesProps {
  event_info: any;
}

export const ViewEventJudges: React.FC<ViewEventJudgesProps> = ({ event_info }) => {
  const [ user, setUser ]: any = userLoginHook();
  const [ eventJudges , setEventJudges] = React.useState<JudgeInfo[]>([]);

  const getEventJudges = async() =>{
    try{
      const response: any = await ABOClientApi.get_event_judges("", event_info.event_id.toString());
      console.log("getEventJudges", response);
      setEventJudges(response);
    }catch(error){
      console.log("getEventJudges Error", error);
    };
  };

  React.useEffect(() => {
    getEventJudges();
  }, []);

  return (
    <>
      <Box>
        <div style={{
          // display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
          width: "100%"
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Event Judges
            {
              user && JSON.parse(user).user_role === "abo_admin" && <AddEventJudgesModal event_info={event_info} getEventJudges={getEventJudges}/>
            }
          </Typography>
        </div>
        {
          eventJudges.map(( eventJudge: JudgeInfo ) => (
            <Box key={eventJudge?.info?.user_id} mb={3} style={{ padding: "5px", border: "1px white solid"}}>
              <Card sx={{ display: 'flex', mb: 2 }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography id="modal-title" variant="h6" component="h2" gutterBottom> 
                    {eventJudge?.info?.user_firstname}
                  </Typography>
                  <CardMedia
                    component="img"
                    sx={{ width: 150, height: 150, objectFit: 'cover' }}
                    image={eventJudge?.info?.user_image}
                    alt={eventJudge?.info?.user_firstname}
                  />
                  <Typography variant="body2" mt={1}>
                    {/* Add extra boxer information here */}
                    {eventJudge?.info?.user_lastname}
                  </Typography>
                </Box>
              </Card>
            </Box>
          ))
        }
      </Box>
    </>
  );
};