// ImagePopup.tsx

import React, { useState, useCallback } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';

interface ImagePopupProps {
  src: string;
  alt: string;
}

export const ImagePopup: React.FC<ImagePopupProps> = ({ src, alt }) => {
  const [open, setOpen] = useState(false);
  const [zoom, setZoom] = useState(1);

  const handleClickOpen = () => {
    setOpen(true);
    setZoom(1); // Reset zoom when opening
  };

  const handleClose = () => {
    setOpen(false);
  };

  const zoomIn = useCallback(() => {
    setZoom(prev => Math.min(prev + 0.1, 2)); // Limit max zoom to 2x
  }, []);

  const zoomOut = useCallback(() => {
    setZoom(prev => Math.max(prev - 0.1, 1)); // Limit min zoom to 1x
  }, []);

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        View Score Card
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="image-dialog-title"
        aria-describedby="image-dialog-description"
        maxWidth="lg"
      >
        <DialogTitle id="image-dialog-title">
          Score Card
        <div style={{ position: 'absolute', top: 10, right: 10 }}>
            <IconButton onClick={zoomIn} color="inherit">
              <ZoomInIcon />
            </IconButton>
            <IconButton onClick={zoomOut} color="inherit">
              <ZoomOutIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent style={{ overflow: 'scroll', position: 'relative' }}>
          <img 
            src={src} 
            alt={alt} 
            style={{
              width: '100%',
              transform: `scale(${zoom})`,
              transformOrigin: '0 0', // Zoom from the top-left corner
            }}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
