import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import { ImageSelector } from './imageTools';
import { useDarkMode } from '../../hooks/useDarkMode';
interface AddBoutScoresModalProps {
  bout_info: any;
}

export const AddBoutScoresModal: React.FC<AddBoutScoresModalProps> = ({ bout_info }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => {
    setOpen(true);
    getBoutOfficials();
    console.log("bout_info", bout_info);
  };

  const [ base64Image, setBase64Image ] = useState("");
  const [ boutOfficials, setBoutOffcials ]: any = useState();
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ] = jwtHook<any>();
  const [ status, setStatus ] = useState<string | null>(null);
  const [ darkMode, setDarkMode ]: any = useDarkMode();

  const [ boutScores, setBoutScores ]: any = useState({
    bout_id: bout_info.bout_id,
    boxer_id: "",
    judge1_id: "",
    judge2_id: "",
    judge3_id: "",
    judge1_score: "",
    judge2_score: "",
    judge3_score: "",
    judge1_note: "",
    judge2_note: "",
    judge3_note: "",
    round_number: "",
    bout_total_score: "",
    bout_score_note: "",
    bout_score_card_image: ""
  });

  const addBoutScores = async() =>{
    console.log("boutScores", boutScores);
    try{
      const addBoutScoresRes = await ABOClientApi.add_bout_scores(
        jwt, 
        boutScores.bout_id.toString(), 
        boutScores.boxer_id.toString(), 
        boutScores.judge1_id.toString(), 
        boutScores.judge2_id.toString(), 
        boutScores.judge3_id.toString(), 
        boutScores.judge1_score.toString(), 
        boutScores.judge2_score.toString(), 
        boutScores.judge3_score.toString(), 
        boutScores.judge1_note,
        boutScores.judge2_note, 
        boutScores.judge3_note, 
        boutScores.round_number.toString(), 
        boutScores.bout_total_score.toString(), 
        boutScores.bout_score_note, 
        boutScores.bout_score_card_image
      );
      console.log("addBoutScoresRes", addBoutScoresRes);
      addBoutScoresRes === "ok" ? setStatus("success") : setStatus("error");
    }catch(error){
      console.log("addBoutScores Error", error);
      setStatus("error");
    };
  };

  const getBoutOfficials = async() =>{
    try{
      const boutOfficialsres:any = await ABOClientApi.get_bout_officials( jwt, bout_info.bout_id.toString() );
      console.log("boutOfficialsres", boutOfficialsres);
      setBoutOffcials(boutOfficialsres);
    }catch(error){
      console.log("getBoutOfficials Error", error);
    };
  };

  interface SelectJudgeProps {
    judgeNumber: number;
  }
  const SelectJudge: React.FC<SelectJudgeProps> = ({judgeNumber}) => {
    return (
      <FormControl sx={{ width: 200, margin: "5px"}}>
        <InputLabel id="SelectJudge_label">Select Judge</InputLabel>
        <Select
          labelId="SelectJudge_label"
          id="SelectJudge"
          value={boutScores[`judge${judgeNumber}_id`]}
          label="Judges"
          onChange={(event: any) => setBoutScores({ ...boutScores, [`judge${judgeNumber}_id`]: event.target.value })}
        >
          {
            boutOfficials && Array.isArray(boutOfficials.judges) && boutOfficials.judges.map(( judge: any ) => (
              <MenuItem value={judge.info.user_id}>{judge.info.user_firstname} {judge.info.user_lastname}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    );
  };

  const SelectBoxer = () => {
    return (
      <FormControl sx={{ width: 200, margin: "5px", textAlign: "left"}}>
        <InputLabel id="SelectBoxer1_label">Select Boxer 1</InputLabel>
        <Select
          labelId="SelectBoxer1_label"
          id="SelectBoxer1"
          value={boutScores.boxer_id}
          label="Boxer 1"
          onChange={(event: any) => setBoutScores({ ...boutScores, boxer_id: event.target.value })}
        >
          <MenuItem value={bout_info.bout_boxer_id_1.toString()}>{bout_info.boxer_id_1_info.boxer_firstname + " " + bout_info.boxer_id_1_info.boxer_lastname}</MenuItem>
          <MenuItem value={bout_info.bout_boxer_id_2.toString()}>{bout_info.boxer_id_2_info.boxer_firstname + " " + bout_info.boxer_id_2_info.boxer_lastname}</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const divStyle = {
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
    width: "100%",
    border: `1px solid ${darkMode ? '#fff' : '#000'}`,
    padding: '5px'
  };

  return (
    <>
      <Button onClick={handleOpen} variant="contained">Add Scores</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '500px',
            borderRadius: '16px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
          }
        }}
      >
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            Add Bout Scores
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <SelectBoxer />
            <hr />
            <div style={divStyle}>
              <Typography id="modal-title" variant="h6" component="h2">
                Judge 1
              </Typography>
              <SelectJudge judgeNumber={1}/>
              <TextField
                id="outlined-basic"
                label="Score"
                variant="outlined"
                style={{margin: "5px"}}
                value={boutScores.judge1_score}
                onChange={(event: any) => setBoutScores({ ...boutScores, judge1_score: event.target.value })}
              />
              <br />
              <TextField
                id="outlined-basic"
                label="Note"
                variant="outlined"
                style={{margin: "5px"}}value={boutScores.judge1_note}
                onChange={(event: any) => setBoutScores({ ...boutScores, judge1_note: event.target.value })}
              />
            </div>
            <br />
            <div style={divStyle}>
              <Typography id="modal-title" variant="h6" component="h2">
                Judge 2
              </Typography>
              <SelectJudge judgeNumber={2}/>
              <TextField
                id="outlined-basic"
                label="Score"
                variant="outlined"
                style={{margin: "5px"}}
                value={boutScores.judge2_score}
                onChange={(event: any) => setBoutScores({ ...boutScores, judge2_score: event.target.value })}
              />
              <TextField
                id="outlined-basic"
                label="Note"
                variant="outlined"
                style={{margin: "5px"}}
                value={boutScores.judge2_note}
                onChange={(event: any) => setBoutScores({ ...boutScores, judge2_note: event.target.value })}
              />
            </div>
            <br />
            <div style={divStyle}>
              <Typography id="modal-title" variant="h6" component="h2">
                Judge 3
              </Typography>
              <SelectJudge judgeNumber={3}/>
              <TextField
                id="outlined-basic"
                label="Score"
                variant="outlined"
                style={{margin: "5px"}}
                value={boutScores.judge3_score}
                onChange={(event: any) => setBoutScores({ ...boutScores, judge3_score: event.target.value })}
              />
              <TextField
                id="outlined-basic"
                label="Note"
                variant="outlined"
                style={{margin: "5px"}}
                value={boutScores.judge3_note}
                onChange={(event: any) => setBoutScores({ ...boutScores, judge3_note: event.target.value })}
              />
            </div>
            <div style={divStyle}>
              <TextField
                  id="outlined-basic"
                  label="Winning Round"
                  variant="outlined"
                  style={{margin: "5px"}}
                  value={boutScores.round_number}
                  onChange={(event: any) => setBoutScores({ ...boutScores, round_number: event.target.value })}
                />
                <TextField
                  id="outlined-basic"
                  label="Total Score"
                  variant="outlined"
                  style={{margin: "5px"}}
                  value={boutScores.bout_total_score}
                  onClick={(event: any) => setBoutScores({ 
                    ...boutScores, 
                    bout_total_score: Number(boutScores.judge1_score) + Number(boutScores.judge2_score) + Number(boutScores.judge3_score) 
                  })}
                />
                <br />
                <TextField
                  id="outlined-basic"
                  label="Bout Note"
                  variant="outlined"
                  style={{margin: "5px"}}
                  onChange={(event: any) => setBoutScores({ ...boutScores, bout_score_note: event.target.value })}
                />           
            </div>
            <div style={divStyle}>
              <ImageSelector 
                base64Image={base64Image}
                setBase64Image={setBase64Image}
                boutScores={boutScores}
                setBoutScores={setBoutScores}
              />  
            </div>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button color="primary" variant="contained" onClick={addBoutScores}>Add Scores</Button>
            <Button color="secondary" variant="contained" onClick={handleClose}>Close</Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};