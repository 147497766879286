import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField  } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import CountrySelect from "./CountrySelect";
import { ImageSelector } from "./imageTools"

interface AddOrganizationProps {

};

export const AddOrganizationDialog: React.FC<AddOrganizationProps> = ({}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => { 
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ base64Image, setBase64Image ] = React.useState<string>('');
  const [ orgInfo, setOrgInfo] = React.useState({ 
    organization_name: "", 
    organization_shortname: "",
    organization_email: "",
    organization_phone: "",
    organization_address: "",
    organization_city: "", 
    organization_state: "", 
    organization_postalcode: "", 
    organization_country: "", 
    org_image: base64Image
  });
  const [ status, setStatus ] = React.useState("");

  const addOrg = async () => {
    console.log("org info", orgInfo);
    console.log("jwt", jwt);
    const validate: any = await validateOrgInfo();
    console.log("validate", Object.keys(validate).length);
    Object.keys(validate).length > 0 && setStatus(Object.values(validate).join(', '));
    if(Object.keys(validate).length > 0)return;
    try {
      setStatus("Adding Organization...");
      const response = await ABOClientApi.create_organization(
        jwt, 
        orgInfo.organization_name, 
        orgInfo.organization_shortname, 
        orgInfo.organization_email, 
        orgInfo.organization_phone, 
        orgInfo.organization_address, 
        orgInfo.organization_city, 
        orgInfo.organization_state, 
        orgInfo.organization_postalcode, 
        orgInfo.organization_country, 
        orgInfo.org_image
      );
      setStatus(response);
      console.log(response);
      response === "ok" && setStatus(`Organization ${orgInfo.organization_name} Added.`);
      response === "ok" && alert(`Organization ${orgInfo.organization_name} Added.`);
    } catch (error) {
      console.log("registerOrg Error", error);
      setStatus("Error Adding Organization");
      alert("Error Adding Organization");
    }
  };  

  const handleInputChange = (field: string, event: any) => {
    //console.log("input change ", field, event )
    setOrgInfo({ ...orgInfo, [field]: event.target.value });
  };
  const handleCountryOriginChange = (event: any) => {
    console.log("country change", event)
    setOrgInfo({ ...orgInfo, ["organization_country"]: JSON.stringify(event) })
  };

  const validateOrgInfo: any = async () => {
    const errors: any = {};
    !orgInfo.organization_name.trim() && (errors.organization_name = 'Organization Name is required');
    !orgInfo.organization_shortname.trim() && (errors.organization_shortname = 'Organization Short Name is required');
    !orgInfo.organization_email.trim() && (errors.organization_email = 'Email is required') || !/\S+@\S+\.\S+/.test(orgInfo.organization_email) && (errors.organization_email = 'Email address is invalid');
    //!orgInfo.organization_phone.trim() && (errors.organization_phone = 'Phone number is required') || !/^\d{10}$\S+\-/.test(orgInfo.organization_phone) && (errors.organization_phone = 'Phone number is invalid');
    // !orgInfo.organization_address.trim() && (errors.organization_address = 'Address is required');
    // !orgInfo.organization_city.trim() && (errors.organization_city = 'City is required');
    // !orgInfo.organization_state.trim() && (errors.organization_state = 'State is required');
    // !orgInfo.organization_postalcode.trim() && (errors.organization_postalcode = 'Postal Code is required');
    // !orgInfo.organization_country.trim() && (errors.organization_country = 'Country is required');
    return errors;
  };


  return (
    <>
      <Button onClick={handleOpen}>Add Ogranization</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '500px',
            borderRadius: '16px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
          }
        }}
      >
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <Typography variant="h5" style={{ fontWeight: 'bold', color: '#333' }}>
            Add Ogranization
          </Typography>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <form style={{ display: 'grid', gap: '10px', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
              <ImageSelector
                base64Image={base64Image}
                setBase64Image={setBase64Image}
                orgInfo={orgInfo}
                setOrgInfo={setOrgInfo}
              />
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Organization Name"
                variant="outlined"
                value={orgInfo.organization_name}
                onChange={(event: any) => handleInputChange("organization_name", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Organization Short Name"
                variant="outlined"
                value={orgInfo.organization_shortname}
                onChange={(event: any) => handleInputChange("organization_shortname", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Organization Email"
                variant="outlined"
                value={orgInfo.organization_email}
                onChange={(event: any) => handleInputChange("organization_email", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Organization Phone"
                variant="outlined"
                value={orgInfo.organization_phone}
                onChange={(event: any) => handleInputChange("organization_phone", event)}
              />
              
              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Organization Address"
                variant="outlined"
                value={orgInfo.organization_address}
                onChange={(event: any) => handleInputChange("organization_address", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px"}}
                type="text"
                label="Organization City"
                variant="outlined"
                value={orgInfo.organization_city}
                onChange={(event: any) => handleInputChange("organization_city", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Organization State"
                variant="outlined"
                value={orgInfo.organization_state}
                onChange={(event: any) => handleInputChange("organization_state", event)}
              />

              <TextField
                style={{ width: "200px", margin: "5px" }}
                type="text"
                label="Organization Postal Code"
                variant="outlined"
                value={orgInfo.organization_postalcode}
                onChange={(event: any) => handleInputChange("organization_postalcode", event)}
              />

              <CountrySelect handleInputChange={handleCountryOriginChange} label="Organization Country" />
            </form>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button variant="contained" color="primary" onClick={ ()=>addOrg() }>
              Add Organization
            </Button>
            <Button color="secondary" variant="contained" onClick={ ()=>handleClose() }>
              Close
            </Button> 
          </div>
          { status && 
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: "red" }}>
              <Typography variant="h6">
                {status}
              </Typography>
            </div> 
          } 
        </DialogActions>
      </Dialog>
    </>
  );
};