import * as React from "react";
import { Typography, TextField, Button } from "@mui/material/";
import { ABOClientApi } from "../../api/ABOApi";
import { useNavigate } from "react-router-dom";
import { menuHook } from "../../hooks/menuHook";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import "./ChangePasswordCard.css";

export const ChangePasswordCard = () => {

  const [ newPassword, setNewPassword] = React.useState("");
  const [ auth_code, setAuthCode] = React.useState("");
  const [ menuItem, setMenuItem ]: any = menuHook();
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  const [ status, setStatus ] = React.useState("");
  const [ authForm, setAuthForm ] = React.useState(false);
  const navigate = useNavigate();

  const getAuthCode = async () => {
    try{
      setStatus("Getting Auth Code");
      const getAuthResponse = await ABOClientApi.reset_password(JSON.parse(user).username, "", "");
      console.log("getAuthResponse", getAuthResponse);
      setStatus("");
      setAuthForm(true);
    }catch(error){
      console.log("getAuthCode Error", error);
      setStatus("Get Auth Code Error");
    }

  };

  const savePassword = async () => {
    try{
      setStatus("Saving Password");
      const savePasswordResponse = await ABOClientApi.reset_password(JSON.parse(user).username, auth_code, newPassword);
      console.log("savePasswordResponse", savePasswordResponse);
      setStatus("");
      setAuthCode("");
      setNewPassword("");
    }catch(error){
      console.log("savePassword Error", error);
      setStatus("Save Password Error");
    };
  };

  return (
    <>
    { 
      authForm === false &&
      <div className="LoginCard">
        <Typography variant="h5">Password Reset</Typography>
        <form>
          <TextField
            style={{ width: "200px", margin: "5px" }}
            type="text"
            label="Username"
            variant="outlined"
            value={JSON.parse(user).username}
            disabled
            // onChange={(event: any) => handleInputChange("username", event)}
          />
          <Typography variant="body2" color="text.secondary">
              Once you press the "Get Code" button, check your email for the AUTH CODE.
          </Typography>
          <Button variant="contained" color="primary" onClick={()=>getAuthCode()}>
            Get Code
          </Button>
          <br />
          {status && <div>{status}</div>}
        </form>
      </div>
    }
    {
      authForm === true &&
      <div className="LoginCard">
        <Typography variant="h5">Password Reset</Typography>
        <form>
          <TextField
            style={{ width: "200px", margin: "5px" }}
            type="text"
            label="Auth Code"
            variant="outlined"
            value={auth_code}
            onChange={(event: any) => setAuthCode(event.target.value)}
          />
          <br />
          <TextField
            style={{ width: "200px", margin: "5px" }}
            type="password"
            label="password"
            variant="outlined"
            value={newPassword}
            onChange={(event: any) => setNewPassword(event.target.value)}
          />
          <br />
          <Button variant="contained" color="primary" onClick={()=>savePassword()}>
            Save Password
          </Button>
          <br />
          {status && <div>{status}</div>}
        </form>
      </div>
    }
    </>
  )
}