import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField } from '@mui/material';
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import { EditUserCard } from "../EditUserCard/EditUserCard";
import moment from 'moment';

export const ViewUsersTable = () => {
  const [rows, setRows]: any = React.useState([]);
  const [ jwt, setJWT ]: any = jwtHook();
  const [ loading, setLoading ] = React.useState<boolean | null>(null);
  const [searchTerm, setSearchTerm] = React.useState('');

  const getUsers = async () => {
    console.log("jwt", jwt);
    try {
      const response = await ABOClientApi.get_all_users( jwt );
      console.log(response);
      setRows(response);
    } catch (error) {
      console.log("getUsers Error", error);
    }
  };

  const formatDate = (date: any) => {
    // console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY');
  };
  

  React.useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      {
        loading ? <div>Loading...</div> :
        <>
        <TextField 
          label="Search User" 
          variant="outlined" 
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          //fullWidth
          margin="normal"
        /> 
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="center">User Image</TableCell>
                <TableCell align="center">Username</TableCell>
                <TableCell align="left">First Name</TableCell>
                <TableCell align="left">Last Name</TableCell>
                <TableCell align="left">Email</TableCell>
                <TableCell align="left">Phone</TableCell>
                <TableCell align="left">Address</TableCell>
                <TableCell align="left">City</TableCell>
                <TableCell align="left">State</TableCell>
                <TableCell align="left">Postal Code</TableCell>
                <TableCell align="left">Country</TableCell>
                <TableCell align="left">Role</TableCell>
                <TableCell align="left">Organization</TableCell>
                <TableCell align="left">Enabled</TableCell>
                <TableCell align="left">Created</TableCell>
                <TableCell align="left">Last Login</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { rows && Array.isArray(rows) && rows.filter((row: any) => 
                  row.user_firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  row.user_lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  row.user_email.toLowerCase().includes(searchTerm.toLowerCase()) ||
                  row.user_id.toString().includes(searchTerm)
                ).map((row: any) => (
                <TableRow key={row.user_id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">{row.user_id}</TableCell>
                  <TableCell align="center"><img src={row.user_image} alt="image of user" width="100"/></TableCell>
                  <TableCell align="left">{row.username}</TableCell>
                  <TableCell align="left">{row.user_firstname}</TableCell>
                  <TableCell align="left">{row.user_lastname}</TableCell>
                  <TableCell align="left">{row.user_email}</TableCell>
                  <TableCell align="left">{row.user_phone}</TableCell>
                  <TableCell align="left">{row.user_address}</TableCell>
                  <TableCell align="left">{row.user_city}</TableCell>
                  <TableCell align="left">{row.user_state}</TableCell>
                  <TableCell align="left">{row.user_postalcode}</TableCell>
                  <TableCell align="left">{row.user_country && JSON.parse(row.user_country).label}</TableCell>
                  <TableCell align="left">{row.user_role}</TableCell>
                  <TableCell align="left">{row.organization_name}</TableCell>
                  <TableCell align="left">{row.enabled}</TableCell>              
                  <TableCell align="left">{formatDate(row.created)}</TableCell>
                  <TableCell align="left">{formatDate(row.last_login)}</TableCell>
                  <TableCell align="left"><EditUserCard userInfoProp={row} getUsers={getUsers} /></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </>
      }
    </>
  );
}


