import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Collapse, } from '@mui/material';
import { styled } from '@mui/system';
import { ABOClientApi } from "../../api/ABOApi";
import { userLoginHook, jwtHook } from "../../hooks/userHooks";
import moment from 'moment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { AddBoutScoresModal } from '../AddBoutScores/AddBoutScoresModal';
import { AddBoutRecordModal } from '../AddBoutRecordCard/AddBoutRecordModal';
import { ImagePopup } from "./ImagePopup";
import { EditBout } from '../EditBout/EditBoutModal';


// Styled components for custom styling
const BoxerProfileCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  // margin: 'auto',
  maxWidth: "100%",
  marginTop: theme.spacing(2),
  padding: theme.spacing(2),
  '& img': {  // Style for the image inside the card
    width: '200px',  // Adjust size as necessary
    height: '200px', // Make sure it's square for profile images, or adjust as needed
    objectFit: 'cover',  // This will make sure the image isn't stretched
    marginRight: theme.spacing(2)
  }
}));

const ProfileImage = styled('img')({
  width: 150,
  height: 150,
  objectFit: 'cover',
});

const StatsBox = styled(Box)({
  flexGrow: 1,
  paddingLeft: '16px',
});

interface BoxerRecordsProps {
  boxerInfo: any;
};

export const BoxerRecord = ({boxerInfo}: BoxerRecordsProps) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [ user, setUser ]: any = userLoginHook();

  const handleOpenRecord = () => {
    console.log("opening records");
    // getBoxerBouts();
    handleOpen();
  };

  const getBoxerBouts = async () => {
    console.log("boxer id", boxerInfo.boxer_id);
    try {
      const response = await ABOClientApi.get_bouts_by_boxer_id("", boxerInfo.boxer_id);
      console.log("boxer bouts", response);
      console.log(response);
    } catch (error) {
      console.log("getBoxerBouts Error", error);
    }
  };
  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY');
  };

  const calculateAge = (dobTimestamp: number): number => {
      // Convert Unix timestamp (which is in seconds) to milliseconds for JavaScript Date object
      const birthDate = new Date(dobTimestamp * 1000);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      
      // Check if birthday hasn't occurred this year
      const hasBirthdayOccurredThisYear = 
        today.getMonth() > birthDate.getMonth() || 
        (today.getMonth() === birthDate.getMonth() && today.getDate() >= birthDate.getDate());
    
      if (!hasBirthdayOccurredThisYear) {
        age--;
      }
      return age;
  };

  return (
    <div>
      <Button variant="contained" onClick={()=>handleOpenRecord()}>Record</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '100%',
            borderRadius: '16px',
            boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
          }
        }}
      >
        <DialogTitle style={{ padding: '24px 24px 0', borderBottom: '1px solid #e0e0e0' }}>
          <BoxerProfileCard>
            <ProfileImage 
              src={boxerInfo.boxer_image}
              alt={`${boxerInfo.boxer_firstname}'s profile`} 
            />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Typography gutterBottom variant="h5" component="div">
                {boxerInfo.boxer_firstname} {boxerInfo.boxer_lastname}(ID: {boxerInfo.boxer_id})
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Age: {calculateAge(boxerInfo.boxer_dob)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Country of Origin: {boxerInfo.boxer_country_origin && JSON.parse(boxerInfo.boxer_country_origin).label}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Division: {boxerInfo.boxer_division}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Weight: {boxerInfo.boxer_weight}lbs
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Height: {boxerInfo.boxer_height} in
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Reach: {boxerInfo.boxer_reach} in
              </Typography>
            </div>
          </BoxerProfileCard>
        </DialogTitle>
        <DialogContent style={{ padding: '24px' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <StatsBox>
              <Typography variant="body2" color="text.secondary">
                Bouts: { user && JSON.parse(user).user_role === "abo_admin" && <AddBoutRecordModal /> }
              </Typography>
              <BoutTable boxerInfo={boxerInfo} />
            </StatsBox>
          </div>
        </DialogContent>
        <DialogActions style={{ padding: '8px 24px 24px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button color="secondary" variant="contained" onClick={ ()=>handleClose() }>
              Close
            </Button> 
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

interface BoxerProfileCardProps {
  boxerInfo: {
    bouts: any[];
    boxer_id: number;
    boxer_firstname: string;
    boxer_lastname: string;
  };
}

const BoutTable: React.FC<BoxerProfileCardProps> = ({ boxerInfo }) => {
  const [expanded, setExpanded] = React.useState<number | null>(null);
  const [ user, setUser ]: any = userLoginHook();
  const [ jwt, setJWT ]: any = jwtHook();
  
  const handleExpandClick = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  const formatDate = (date: any) => {
    console.log("date", date);
    return moment(date*1000).format('MMMM Do YYYY');
  };

  console.log("boxerInfo", boxerInfo);


  return (
    <TableContainer component={Paper}>
      <Table aria-label="bouts table">
        <TableHead>
          <TableRow>
            <TableCell>Opponent</TableCell>
            <TableCell>Weight/In(LBS)</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Location</TableCell>
            <TableCell>Rounds</TableCell>
            <TableCell>Result</TableCell>
            <TableCell>Weight Class</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {boxerInfo.bouts.map((bout, index) => (
            <React.Fragment key={index}>
              <TableRow>
                <TableCell>
                  {
                    boxerInfo.boxer_id === bout.bout_boxer_id_1 ? 
                       `${bout.boxer_id_2_info.boxer_firstname} ${bout.boxer_id_2_info.boxer_lastname}(${bout.boxer_id_2_info.boxer_id})`
                       : 
                       `${bout.boxer_id_1_info.boxer_firstname} ${bout.boxer_id_1_info.boxer_lastname} | (ID: ${bout.boxer_id_1_info.boxer_id})`
                  }
                </TableCell>
                <TableCell>{boxerInfo.boxer_id === bout.bout_boxer_id_1 ? bout.bout_boxer_id_2_weight : bout.bout_boxer_id_1_weight}lbs</TableCell>
                <TableCell>{formatDate(bout.bout_date_time)}</TableCell>
                <TableCell>{bout.event.event_name}</TableCell>
                <TableCell>{bout.event.event_country !== "" && JSON.parse(bout.event.event_country).label}</TableCell>
                <TableCell >{bout.bout_actual_rounds}/{bout.bout_planned_rounds}</TableCell>
                <TableCell style={{  backgroundColor: boxerInfo.boxer_id === bout.bout_boxer_id_winner ? 'green' : 'red'}}>
                  {boxerInfo.boxer_firstname} {boxerInfo.boxer_lastname} {<br />} {boxerInfo.boxer_id === bout.bout_boxer_id_winner ? "Won" : "Lost"} By {bout.bout_result}
                </TableCell>
                <TableCell>{bout.bout_weight_class}</TableCell>
                <TableCell align="right">
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => handleExpandClick(index)}
                  >
                    {expanded === index ? <>scores<ExpandLessIcon /> </> : <>scores<ExpandMoreIcon /></>}
                  </IconButton>
                </TableCell>
                <TableCell>
                  { user && JSON.parse(user).user_role === "abo_admin" && <EditBout bout_info={bout} />}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                  <Collapse in={expanded === index} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                      <Typography variant="h6" gutterBottom component="div">
                        Scores { user && JSON.parse(user).user_role === "abo_admin" && <AddBoutScoresModal bout_info={bout} /> }
                      </Typography>
                      {bout.scores.length > 0 ? (                        
                        <>
                          <Box>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell align="center">Judge 1</TableCell>
                                  <TableCell align="center">Judge 2</TableCell>
                                  <TableCell align="center">Judge 3</TableCell>
                                  <TableCell align="center">Score Card</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>Score</TableCell>
                                  {['judge1_score', 'judge2_score', 'judge3_score'].map((judgeScore, index) => (
                                    <TableCell key={index} align="center">
                                      {bout.scores.find((score: any) => bout.bout_boxer_id_1 === score.boxer_id)?.[judgeScore]}
                                    </TableCell>
                                  ))}
                                  <TableCell></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>Note</TableCell>
                                  {['judge1_note', 'judge2_note', 'judge3_note'].map((judgeNote, index) => (
                                    <TableCell key={index} align="center">
                                      {bout.scores.find((score: any) => bout.bout_boxer_id_1 === score.boxer_id)?.[judgeNote]}
                                    </TableCell>
                                  ))}
                                  <TableCell>
                                    <Box>
                                      <ImagePopup src={bout.scores.find((score: any) => bout.bout_boxer_id_1 === score.boxer_id)?.bout_score_card_image || ""} alt="score card" />
                                    </Box>
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Typography>No scores available.</Typography>
                          { user && JSON.parse(user).user_role === "abo_admin" && <AddBoutScoresModal bout_info={bout} /> }
                        </>
                      )}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};